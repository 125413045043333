import React, { useRef, useState } from 'react';
import { Box, Button, Typography, IconButton, Modal, Checkbox} from '@mui/material';
import {  ErrorOutline, Close } from '@mui/icons-material';
import { toast } from 'sonner';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import * as XLSX from 'xlsx';
import ScheduleModal from './components/ScheduleModal';
import PreviewModal from './components/PreviewModal';
import ConfirmationModal from './components/ConfirmationModal';
import FileUpload from './components/FileUpload';
import FileDetails from './components/FileDetails';
import { validateFile, formatFileSize } from './utils/fileUtils';

const BulkOutbound = ({ assistantId, phone }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewData, setPreviewData] = useState(null);
    const [scheduleDate, setScheduleDate] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const fileInputRef = useRef(null);
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [scheduleFile, setScheduleFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(null);
    const [totalRows, setTotalRows] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const handlePreviewBack = () => {
        setPreviewModalOpen(false);
        setScheduleModalOpen(true);
        setIsEditMode(true);
    };


    const handleFileUpload = async (file) => {
        setIsUploading(true);
        try {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const base64Data = event.target.result;
                const fileData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    content: base64Data
                };
                localStorage.setItem('BulkOutboundFile', JSON.stringify(fileData));
                setSelectedFile({
                    name: file.name,
                    size: file.size,
                    type: file.type
                });
                toast.success('File uploaded successfully');
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
        } finally {
            setIsUploading(false);
        }
    };

    const downloadSampleFile = () => {
        window.open("https://docs.google.com/spreadsheets/d/1clW5ehCXvDSMsqvyVKmIm1wR2mBgF4XKWzLJVk4i7vs/edit?usp=sharing", "_blank");
    };

    const handleDelete = async () => {
        try {
            setSelectedFile(null);
            localStorage.removeItem('BulkOutboundFile');
            setDeleteModalOpen(false);
            setConsentChecked(false);
            toast.success('File deleted successfully');
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error('Error deleting file');
        }
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file && validateFile(file)) {
            setScheduleFile(file);
        }
    };

    const handleScheduleFileUpload = (event) => {
        const file = event.target.files?.[0];
        if (file && validateFile(file)) {
            setScheduleFile(file);
            storeFileInLocalStorage(file);
        }
    };

    const storeFileInLocalStorage = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            localStorage.setItem('scheduledFile', data);
        };
        reader.readAsDataURL(file);
    };

    const handleScheduleSubmit = () => {
        const newErrors = {};
        if (!campaignName) newErrors.campaignName = true;
        if (!campaignDescription) newErrors.campaignDescription = true;
        if (!scheduleFile) newErrors.scheduleFile = true;
        if (!scheduleDate) newErrors.scheduleDate = true;
        if (!scheduleTime) newErrors.scheduleTime = true;

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setScheduleModalOpen(false);
        setPreviewModalOpen(true);
        loadPreviewData();
    };

    const loadPreviewData = () => {
        const fileData = localStorage.getItem('scheduledFile');
        if (fileData) {
            const base64 = fileData.split(',')[1];
            const workbook = XLSX.read(base64, { type: 'base64' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            
            const previewRows = data.slice(0, 11);
            setPreviewData(previewRows);
            setTotalRowCount(data.length - 1);
        }
    };

    const startBulkOutboundCall = () => {
        console.log('Starting bulk call...');
    };

    const handleScheduleCall = () => {
        setScheduleModalOpen(true);
    };

    return (
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column', 
            gap: '1rem', 
            padding: '1.5rem', 
            marginTop: '2rem',  
            borderRadius: "6px", 
            bgcolor: "rgba(177, 177, 190, 0.11)", 
            border: "1px solid #A9A9A9"
        }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>Bulk Outbound Calls</Typography>
            <Typography variant="body1" sx={{ color: '#666' }}>Add Contacts via CSV</Typography>

            {!selectedFile ? (
                <FileUpload onFileUpload={handleFileUpload} downloadSampleFile={downloadSampleFile} validateFile={validateFile} />
            ) : (
                <FileDetails file={selectedFile} onUpdate={(e) => handleFileUpload(e.target.files[0])} onDelete={() => setDeleteModalOpen(true)} formatFileSize={formatFileSize} />
            )}

            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                    <Box sx={{ width: "380px", bgcolor: "#FFF", padding: "10px 47px 47px 47px", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div></div>
                            <Close onClick={() => setDeleteModalOpen(false)} />
                        </Box>
                        <div style={{ display: "flex", flexDirection: "column", gap: "18.8px", alignItems: "center" }}>
                            <ErrorOutline sx={{ width: "60px", height: "60px" }} />
                            <Typography variant="body1" textAlign="center" fontWeight="500">
                                Are you sure you want to delete this file?
                            </Typography>
                            <Box display="flex" alignItems="center" bgcolor="#F7F9FD">
                                <Checkbox
                                    id="consent-checkbox"
                                    checked={consentChecked}
                                    onChange={(e) => setConsentChecked(e.target.checked)}
                                />
                                <label htmlFor="consent-checkbox" style={{ fontSize: "12.537px" }}>
                                    This will permanently delete the file and all its contents.
                                </label>
                            </Box>
                            <div style={{ width: "100%", display: "flex", gap: "1rem" }}>
                                <Button
                                    variant="contained"
                                    onClick={handleDelete}
                                    disabled={!consentChecked}
                                    sx={{
                                        textWrap: "nowrap",
                                        fontSize: "12.537px",
                                        bgcolor: "#02042D",
                                        textTransform: "none",
                                        padding: "8px 16px"
                                    }}
                                >
                                    Yes, delete this file
                                </Button>
                                <Button
                                    onClick={() => {
                                        setDeleteModalOpen(false);
                                        setConsentChecked(false);
                                    }}
                                    variant="outlined"
                                    sx={{
                                        fontSize: "12.537px",
                                        color: "#02042D",
                                        borderColor: "gray"
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Modal>

            <Box sx={{ display: 'flex', gap: '1rem', mt: 2 }}>
                <Button 
                    variant='contained' 
                    sx={{ 
                        flex: 1,
                        bgcolor: "#02042D", 
                        color: "white",
                        '&:hover': { bgcolor: "#0a0b3b" },
                        '&:disabled': { bgcolor: "rgba(0, 0, 0, 0.12)" }
                    }} 
                    onClick={startBulkOutboundCall}
                    disabled={!selectedFile}
                    startIcon={<CallOutlinedIcon/>}
                >
                    Start Bulk Call
                </Button>
                <Button 
                    variant='outlined' 
                    sx={{ 
                        flex: 1,
                        color: "#02042D",
                        borderColor: "#02042D",
                        '&:hover': { borderColor: "#0a0b3b", bgcolor: 'rgba(2, 4, 45, 0.1)' }
                    }} 
                    onClick={handleScheduleCall}
                    startIcon={<ScheduleSendIcon />}
                >
                    Schedule
                </Button>
            </Box>

            <ScheduleModal
                scheduleModalOpen={scheduleModalOpen}
                setScheduleModalOpen={setScheduleModalOpen}
                isDragging={isDragging}
                isEditMode={isEditMode}
                setIsDragging={setIsDragging}
                scheduleFile={scheduleFile}
                setScheduleFile={setScheduleFile}
                handleDrop={handleDrop}
                handleScheduleFileUpload={handleScheduleFileUpload}
                downloadSampleFile={downloadSampleFile}
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                campaignDescription={campaignDescription}
                setCampaignDescription={setCampaignDescription}
                scheduleDate={scheduleDate}
                setScheduleDate={setScheduleDate}
                scheduleTime={scheduleTime}
                setScheduleTime={setScheduleTime}
                handleScheduleSubmit={handleScheduleSubmit}
                errors={errors}
                fileInputRef={fileInputRef}
                setPreviewModalOpen={setPreviewModalOpen}
                loadPreviewData={loadPreviewData}
                setTotalRows={setTotalRows} // Pass setTotalRows prop
                totalRows={totalRows} // Pass totalRows prop
            />

            <PreviewModal 
                previewModalOpen={previewModalOpen}
                setPreviewModalOpen={setPreviewModalOpen}
                previewData={previewData}
                totalRowCount={totalRowCount}
                scheduleDate={scheduleDate}
                scheduleTime={scheduleTime}
                scheduleFile={scheduleFile}
                setConfirmationModalOpen={setConfirmationModalOpen}
                onBack={handlePreviewBack}
            />

            <ConfirmationModal
                confirmationModalOpen={confirmationModalOpen}
                setConfirmationModalOpen={setConfirmationModalOpen}
                consentChecked={consentChecked}
                setConsentChecked={setConsentChecked}
                previewData={previewData}
                scheduleDate={scheduleDate}
                scheduleTime={scheduleTime}
                setPreviewModalOpen={setPreviewModalOpen}
                campaignName={campaignName}
                campaignDescription={campaignDescription}
                assistantId={assistantId}
                scheduleFile={scheduleFile}
                phone={phone}
            />
        </Box>
    );
};

export default BulkOutbound;