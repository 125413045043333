import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Alert,
  IconButton,
  Box,
  Snackbar,
  TextField,
  CircularProgress,
  InputAdornment,

} from '@mui/material';
import {
  KeyRounded as KeyIcon,
  ContentCopy as CopyIcon,
  Close,
  RemoveCircle,
  Lock as LockIcon,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';

import Navbar from '../components/Navbar';
import api from '../api';

const APIKeyManagement = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewKeyDialog, setShowNewKeyDialog] = useState(false);
  const [newKeyData, setNewKeyData] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [credentials, setCredentials] = useState({ Password: '' });
  const [showPasswordDialog, setShowPasswordDialog] = useState(false); // New state for password dialog
  const [showPassword, setShowPassword] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false); // Remove this line
  const [isRevokeDialogOpen, setRevokeDialogOpen] = useState(false);
  const [selectedApiKeyName, setSelectedApiKeyName] = useState('');


  const handleOpenRevokeDialog = (selectedApiKeyName) => {
    setSelectedApiKeyName(selectedApiKeyName);
    setRevokeDialogOpen(true);
  };

  const handleRevoke = () => {
    handleRevokeKey(selectedApiKeyName);
    setRevokeDialogOpen(false);
  };
  
  const handleCloseRevoke = () => {
    setRevokeDialogOpen(false);
  };
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const [token] = useState(() => {
    const tokenData = localStorage.getItem("token");
    return tokenData ? JSON.parse(tokenData).token : null;
  });

  useEffect(() => {
    if(token){
    fetchApiKeys();
  }
  }, [token]);


  const fetchApiKeys = async () => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      const response = await api.get("/v1/api-key/get-api-key", config);
      const data = response.data;
  
      if (data.success) {
        // Update the state with the list of API keys
        setApiKeys(
          data.data.apiKey.map((apiKeyObj) => ({
            key: apiKeyObj.api_key,
            createdAt: new Date(apiKeyObj.createdAt || Date.now()),
          }))
        );
      } else {
        setSnackbar({ open: true, message: data.message || 'Failed to fetch API keys', severity: 'error' });
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbar({ open: true, message: 'Failed to fetch API keys', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const handleGenerateKey = async () => {
    setIsGenerating(true);
    try {

      const response = await api.post("/v1/api-key/generate-api-key", { Password: credentials.Password }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      
      const data = await response.data;
      if (data.success) {
        setNewKeyData({ apiKey: data.data.apiKey, secretKey: data.data.secretKey });
        setShowNewKeyDialog(true);
        fetchApiKeys();  // Make sure to call fetchApiKeys after key generation
      } else {
        setSnackbar({ open: true, message: data.message || 'Failed to generate API key', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to generate API key', severity: 'error' });
    } finally {
      setIsGenerating(false);
      setShowPasswordDialog(false);  // Close the password dialog
    }
  };
  


  const handleRevokeKey = async () => {
    setIsRevoking(true);
    try {
      const response = await api.post("/v1/api-key/delete-api-key", { apiKey: selectedApiKeyName.key }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
  
      const data = await response.data;
      if (data.success) {
        setSnackbar({ open: true, message: 'API key revoked successfully', severity: 'success' });
        fetchApiKeys(); // Refresh API keys list after revocation
      } else {
        setSnackbar({ open: true, message: data.message || 'Failed to revoke API key', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to revoke API key', severity: 'error' });
    } finally {
      setIsRevoking(false);
      setRevokeDialogOpen(false); // Close the revoke confirmation dialog
    }
  };


  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setSnackbar({ open: true, message: 'Copied to clipboard', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to copy to clipboard', severity: 'error' });
    }
  };

  const handlePasswordSubmit = () => {
    handleGenerateKey(); // Call the handleGenerateKey function when password is submitted
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

  return (
    <>
      <Navbar title="API Key Management" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 4 }}>
        <Box flex={1} sx={{ p: 4, bgcolor: '#ffffff', borderColor: '#E5E7EB', minHeight: '100vh', borderWidth: '1px', borderRadius: '12px', borderStyle: 'solid' }}>
          <Box sx={{ mb: 4, gap: 1 }}>
            <Typography variant="h4" gutterBottom>
              API Keys
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Generate and manage your API keys securely. Never share your secret keys with anyone.
            </Typography>
          </Box>

          <Button
            variant="contained"
            onClick={() => setShowPasswordDialog(true)} // Show password dialog when button is clicked
            disabled={isGenerating}
            startIcon={<KeyIcon />}
            sx={{
              mb: 4,
              bgcolor: '#02042D',
              color: '#fff',
              '&:hover': {
                bgcolor: '#1c2751',
              },
            }}
          >
            Generate New API Key
          </Button>

          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {apiKeys.map((apiKey) => (
                <Paper key={apiKey.key} sx={{ p: 2, bgcolor: '#ffffff', borderColor: '#E5E7EB', height: 'auto', borderWidth: '1px', borderRadius: '8px', borderStyle: 'solid' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2" component="code">
                          {apiKey.key}
                        </Typography>
                        <IconButton size="small" onClick={() => copyToClipboard(apiKey.key)}>
                          <CopyIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        Created on {apiKey.createdAt.toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"

                      onClick={() => handleOpenRevokeDialog(apiKey)}

                      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                      <RemoveCircle fontSize="small" />
                      Revoke
                    </Button>
                  </Box>
                </Paper>
              ))}
            </Box>
          )}

          {/* Revoke Dialog */}
          <Dialog open={isRevokeDialogOpen} onClose={handleCloseRevoke}
          maxWidth="md"
          PaperProps={{
            elevation: 1,
            sx: {
              borderRadius: '16px',
              width: '550px'
            }
          }}>
            <DialogTitle
            sx={{ 
              py: 3,
              px: 4,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              height:'auto',
            }}>
              <Typography sx={{ color: '#02042D', fontSize: '24px' }}>
              Revoke API Key
                </Typography>
              
              </DialogTitle>
            <DialogContent sx={{ px: 4 }}>
            <DialogContentText sx={{ color: '#666', fontSize: '16px' }}>
            Are you sure you want to revoke the API key <strong>{selectedApiKeyName?.key}</strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions
            sx={{ 
              px: 4,
              pb: 3,
              gap: 2
            }}>
              <Button onClick={handleCloseRevoke} variant='outlined'
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 24px',
                fontSize: '16px',
                borderColor: '#E0E0E0',
                color: '#666',
                '&:hover': {
                  borderColor: '#BDBDBD',
                  backgroundColor: 'transparent'
                }
              }}
              >
                Cancel
              </Button>
              <Button onClick={handleRevoke} variant='contained'
              sx={{
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 24px',
                fontSize: '16px',
                backgroundColor: '#1a237e',
                '&:hover': { 
                  backgroundColor: '#0d1442'
                }
              }}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* Password Dialog */}
                  <Dialog 
                  open={showPasswordDialog}
                  maxWidth="md"
                  PaperProps={{
                    elevation: 1,
                    sx: {
                      borderRadius: '16px',
                      width: '550px'
                    }
                  }}
                >
              <DialogTitle 
                sx={{ 
                  py: 3,
                  px: 4,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  height:'auto',
                }}
              >
              <LockIcon sx={{ color: '#02042D', fontSize: '24px' }} />
              <Typography sx={{ color: '#02042D', fontSize: '24px' }}>
                Enter Your Password
                </Typography>
              </DialogTitle>

              <DialogContent sx={{ px: 4, pb: 4 }}>
              <DialogContentText sx={{ color: '#666', fontSize: '16px', mb: 3 }}>
                To generate a new API key, please enter your login password.
              </DialogContentText>
              <TextField
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="outlined"
                value={credentials.Password}
                onChange={(e) => setCredentials({ Password: e.target.value })}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end"
                        sx={{ color: '#757575' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                 
                  '& .MuiOutlinedInput-root': {
                    fontSize: '16px',
                    height: '56px',
                    borderRadius: '12px',
                    '& fieldset': {
                      borderColor: '#E0E0E0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#1a237e',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1a237e',
                    },
                  },
                }}
              />
            </DialogContent>

              <DialogActions 
                sx={{ 
                  px: 4,
                  pb: 3,
                  gap: 2
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setShowPasswordDialog(false)}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                    padding: '8px 24px',
                    fontSize: '16px',
                    borderColor: '#E0E0E0',
                    color: '#666',
                    '&:hover': {
                      borderColor: '#BDBDBD',
                      backgroundColor: 'transparent'
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handlePasswordSubmit}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '8px',
                    padding: '8px 24px',
                    fontSize: '16px',
                    backgroundColor: '#1a237e',
                    '&:hover': { 
                      backgroundColor: '#0d1442'
                    }
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>



          <Dialog 
      open={showNewKeyDialog}
      onClose={() => setShowNewKeyDialog(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          bgcolor: 'background.paper',
          p: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Typography variant="h5" fontWeight={600} color="#02042D" fontSize="24px">
          New API Key Generated
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ bgcolor: '#fff', p: 3 }}>
        <Alert 
          severity="warning" 
          sx={{ 
            mt: 2,
            mb: 2,
            p: 2,
            borderRadius: 2,
            '& .MuiAlert-message': {
              fontSize: '0.9rem'
            }
          }}
        >
          Store your secret key securely - you won't be able to see it again!
        </Alert>

        {newKeyData && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {/* API Key Section */}
            <Box>
              <Typography 
                variant="subtitle1" 
                fontWeight={600} 
                color="#02042D"
                sx={{ mb: 1.5 }}
              >
                API Key
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'grey.200',
                  bgcolor: 'background.paper',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: 'grey.50'
                  }
                }}
              >
                <code style={{ 
                  wordBreak: 'break-word', 
                  width: '80%',
                  fontSize: '0.9rem',
                  color: 'rgba(0,0,0,0.8)'
                }}>
                  {newKeyData.apiKey}
                </code>
                <IconButton
                  onClick={() => copyToClipboard(newKeyData.apiKey)}
                  sx={{
                    bgcolor: 'grey.100',
                    '&:hover': { 
                      bgcolor: 'primary.light',
                      color: 'primary.main'
                    },
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Paper>
            </Box>

            {/* Secret Key Section */}
            <Box>
              <Typography 
                variant="subtitle1" 
                fontWeight={600} 
                color="#02042D"
                sx={{ mb: 1.5 }}
              >
                Secret Key
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  p: 2.5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: 2,
                  border: '2px solid',
                  borderColor: 'warning.light',
                  bgcolor: 'warning.lighter',
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    borderColor: 'warning.main',
                    bgcolor: 'warning.light'
                  }
                }}
              >
                <code style={{ 
                  wordBreak: 'break-word', 
                  width: '80%',
                  fontSize: '0.9rem',
                  color: 'rgba(0,0,0,0.8)'
                }}>
                  {newKeyData.secretKey}
                </code>
                <IconButton
                  onClick={() => copyToClipboard(newKeyData.secretKey)}
                  sx={{
                    bgcolor: 'background.paper',
                    '&:hover': { 
                      bgcolor: 'warning.main',
                      color: 'warning.contrastText'
                    },
                    transition: 'all 0.2s ease-in-out'
                  }}
                >
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Paper>
            </Box>
          </Box>
        )}
      </DialogContent>

      <DialogActions 
        sx={{ 
          bgcolor: 'background.paper',
          p: 3,
          borderTop: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Button
          onClick={() => setShowNewKeyDialog(false)}
          variant="contained"
          sx={{
            px: 4,
            py: 1,
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 600,
            bgcolor: '#192B50',
            '&:hover': { 
              bgcolor: '#02042D'
            }
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>


          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} variant="filled">
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

export default APIKeyManagement;