import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import RefreshIcon from '@mui/icons-material/Refresh';
import api from "../../api";

const GeneratePrompt = ({ token, onPromptGenerated, setPrompt, saveToLocalStorage }) => {
    const [generateKeywords, setGenerateKeywords] = useState('');
    const [generatingPrompt, setGeneratingPrompt] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [hasGenerated, setHasGenerated] = useState(false);

    const handleGeneratePrompt = async () => {
        if (!generateKeywords.trim()) return;
        
        setGeneratingPrompt(true);
        setError('');
        setSuccess('');

        try {
            const response = await api.post("/ai-prompt/generate-prompt", {
                context: generateKeywords
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.data?.success && response.data?.data?.prompt) {
                // Update the main prompt input
                setPrompt(response.data.data.prompt);
                // Save to localStorage if needed
                saveToLocalStorage("Prompt", response.data.data.prompt);
                // Show success message
                setSuccess('Prompt generated successfully!');
                // Set generated state to true
                setHasGenerated(true);
            }
        } catch (error) {
            console.error("Error generating prompt:", error);
            setError('Failed to generate prompt: Please provide more specific information or a relevant context.');
        } finally {
            setGeneratingPrompt(false);
        }
    };

    // Reset states when input changes
    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setGenerateKeywords(newValue);
        setError('');
        setSuccess('');
        // Only reset hasGenerated if the input is cleared
        if (!newValue.trim()) {
            setHasGenerated(false);
        }
    };

    return (
        <Box sx={{ width: '100%', mt: 2 , marginTop:'-8px'}}>
            <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                my: 2,
                '&::before, &::after': {
                    content: '""',
                    flex: 1,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                }
            }}>
                <Typography sx={{ 
                    px: 2, 
                    color: 'rgba(0, 0, 0, 0.6)',
                    fontSize: '0.875rem'
                }}>
                    OR
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        size='small'
                        fullWidth
                        placeholder="Enter keywords to generate prompt...."
                        value={generateKeywords}
                        onChange={handleInputChange}
                        sx={{ bgcolor: "white" }}
                        error={!!error}
                    />
                    <button
                        onClick={handleGeneratePrompt}
                        disabled={generatingPrompt}
                        style={{
                            backgroundColor: '#02042d',
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            padding: '8px 24px',
                            cursor: 'pointer',
                            minWidth: '120px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            fontSize: '14px',
                            opacity: generatingPrompt ? 0.7 : 1
                        }}
                    >
                        {hasGenerated ? (
                            <AutoFixHighIcon sx={{ fontSize: 20 }} />
                        ) : (
                            <AutoFixHighIcon sx={{ fontSize: 20 }} />
                        )}
                        {generatingPrompt 
                            ? "Generating..." 
                            : (hasGenerated ? "Regenerate" : "Generate")}
                    </button>
                </Box>
                {error && (
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: '#d32f2f',
                            fontSize: '1rem',
                            ml: 1
                        }}
                    >
                        {error}
                    </Typography>
                )}
                {success && (
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: '#2e7d32',
                            fontSize: '1rem',
                            ml: 1
                        }}
                    >
                        {success}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default GeneratePrompt;