import { Box, Typography, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from 'react'
import api from '../api';

export const getRemainingDays = (expiryDate) => {
    return Math.max(
        Math.floor(
            (new Date(expiryDate) - new Date()) / (1000 * 60 * 60 * 24)
        ),
        0
    );
};

const Credit = ({ sidebarHidden = true }) => {
    const [credit, setCredit] = useState(0);
    const [allCredit, setAllCredit] = useState(0);
    const [displayCredit, setDisplayCredit] = useState("0");
    const [displayAllCredit, setDisplayAllCredit] = useState("0");
    const [loading, setLoading] = useState(false);
    const [planName, setPlanNae] = useState(null);
    const [planDetails, setPlanDetails] = useState({});
    const [token] = useState(() => {
        const tokenData = localStorage.getItem("token");
        return tokenData ? JSON.parse(tokenData).token : null;
    });
    
    const [id] = useState(() => {
        return localStorage.getItem("Organization");
    })


    // Format number helper function
    const formatNumber = (num) => {
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'k';
        }
        return num;
    };

    const getSubscriptionDetails = useCallback(async () => {
        setLoading(true);
        try {
        const response = await api.get(`/v1/billing/subscription-details`, {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        });
        if (response?.data?.success) {
            const data = {
            start_date: response?.data?.data?.subscriptionDetails?.created,
            end_date:
                response?.data?.data?.subscriptionDetails?.plan_period_end,
            status: response?.data?.data?.subscriptionDetails?.status,
            };
            setPlanDetails(data);
            setPlanNae(response?.data?.data?.plan_name);
            localStorage.setItem("planDetails", JSON.stringify(planDetails));
            localStorage.setItem("planId", response?.data?.data?.plan_name);
        }
        } catch (error) {
        console.error(error);
        localStorage.removeItem("planDetails");
        localStorage.removeItem("planId");
        } finally {
        setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        getSubscriptionDetails();
    }, [token]);

    const getCredit = async () => {
        setLoading(true);
        try {
            const response = await api.post("/organizations/credits", {
                organizationId: id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const currentCredits = response.data.data?.credits || 0;
            const totalCredits = response.data.data?.allCredits || 0;

            // Store actual numerical values
            setCredit(currentCredits);
            setAllCredit(totalCredits);

            // Set display values
            setDisplayCredit(formatNumber(currentCredits));
            setDisplayAllCredit(formatNumber(totalCredits));

            localStorage.setItem("Credit", currentCredits);

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (token) {
            if (credit) {
                setInterval(getCredit, 100);
            } else {
                getCredit();
            }
        }
    }, [token]);

    if (sidebarHidden) {
        return (
            <Box sx={{ 
                position: 'relative', 
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft:'-5px'
            }}>
                {/* Background circle */}
                <CircularProgress
                    variant="determinate"
                    value={100}
                    size={60}
                    thickness={4}
                    sx={{
                        color: 'rgba(255, 255, 255, 0.2)',
                        position: 'absolute'
                    }}
                />
                {/* Progress circle */}
                <CircularProgress
                    variant="determinate"
                    value={(credit / allCredit) * 100}
                    size={60}
                    thickness={4}
                    sx={{
                        color: 'transparent',
                        '& .MuiCircularProgress-circle': {
                            stroke: 'url(#gradient)',
                            strokeLinecap: 'round'
                        }
                    }}
                />
                {/* SVG Gradient Definition */}
                <svg style={{ position: 'absolute', width: 0, height: 0 }}>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" stopColor="#B610B9" />
                            <stop offset="100%" stopColor="#471DFE" />
                        </linearGradient>
                    </defs>
                </svg>
                {/* Center Text */}
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        // flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1px'
                    }}
                >
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: '#fff', 
                            lineHeight: 1,
                            fontSize: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {displayCredit}
                    </Typography>
                    {/* <Typography 
                        variant="caption" 
                        sx={{ 
                            color: 'rgba(255, 255, 255, 0.7)', 
                            lineHeight: 1,
                            fontSize: '10px'
                        }}
                    >
                        /
                    </Typography>
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            color: 'rgba(255, 255, 255, 0.7)', 
                            lineHeight: 1,
                            fontSize: '12px'
                        }}
                    >
                        {displayAllCredit}
                    </Typography> */}
                </Box>
            </Box>
        
        );
    }
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: ".8rem ", border: "1px solid #A9A9A9", padding: ".5rem .8rem", borderRadius: ".5rem" }}>
            {loading ? (<Box
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%', // Optional: Adjust height as per your requirement
                    width: '100%',  // Optional: Adjust width as per your requirement
                    }}
                >
                    <CircularProgress size={20}/>
            </Box>) :
            <>
            
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <Typography sx={{ color: "#FFF", fontWeight:"500", fontSize:"16px" }}>{ planName?`${planName} Plan`:"Currently on Free Plan" }</Typography>
                    <Typography sx={{ color: "#FF4245", fontSize: "12px" }}>{planName?`Expiring in ${getRemainingDays(planDetails?.end_date)} days`:"Upgrade to Activate Plan" }</Typography>
                </Box>
                {planDetails?.status && (
                    <Box
                        style={{
                        backgroundColor:
                            planDetails?.status === "trialing"
                            ? "#FFD700" // Yellow for "On Trial"
                            : planDetails?.status === "cancelled"
                            ? "#FF4F4F" // Red for "Cancelled"
                            : "#37CC4F", // Green for "Active"
                        borderRadius: "5px",
                        padding: "0px 5px",
                        }}
                    >
                        <Typography
                        sx={{
                            color:
                            planDetails?.status === "trialing"
                                ? "#FF4F4F" // Purple for "On Trial" text
                                : "#FFF", // White for all others
                            fontSize: "12px",
                            fontWeight: "500",
                        }}
                        >
                        {(() => {
                            const currentDate = new Date();
                            const planEndDate = new Date(planDetails?.end_date);

                            if (planDetails?.status === "cancelled") {
                              // If canceled and end date is in the future
                              if (planEndDate >= currentDate) {
                                return "Cancelled";
                              }
                            }

                            // If subscription is still active
                            if (planEndDate >= currentDate) {
                            if (planDetails?.status === "trialing") {
                                return "On Trial";
                            } else if (planDetails?.status === "active") {
                                return "Active";
                            }
                            }
                        })()}
                        </Typography>
                    </Box>
                )}
            </Box>

            <div style={{ width: "100%", height: "5px", backgroundColor: "#FFFFFF", borderRadius: "5px" }}>
                <div
                    style={{
                        width: `${Math.min((credit / allCredit) * 100, 100)}%`,
                        height: "5px",
                        background: "linear-gradient(to right, #B610B9, #471DFE)",
                        borderRadius: "5px",
                    }}
                ></div>
            </div>


            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    color: "#FFF"
                }}
            >
                <Typography variant="body2">Minutes Left</Typography>
                <Typography variant="body2">{displayCredit} / {displayAllCredit}</Typography>
            </Box>
        </>}
        </Box>
    )
}

export default Credit;