import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, ToggleButton, ToggleButtonGroup, TextField, Grid, CircularProgress } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import api from "../api";
// Assuming you have a CopyLinkComponent available
import CopyLinkComponent from './CopyLink';
import { toast } from "sonner";
import { formatDate } from '../common/formatDate';

const ManageSharedAccess = ({ onClose, assistantId }) => {
  // Initial empty state for links
  const [links, setLinks] = useState([]);
  const token = JSON.parse(localStorage.getItem("token")).token;
  const [loading, setLoading] = useState(false);

  // Mock function to fetch links (to replace with an actual API call later)
  useEffect(() => {
    fetchLinks();
  }, [token, assistantId]); 

  const fetchLinks = async () => {
    // Mock data (replacing this with our API call in the future)
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await api.get(
        `/v1/assistant/share/${assistantId}`,
        config
      );
      setLinks(data?.data);
    } catch (error) {
      console.error("Error fetching plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleStatus = async (linkId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await api.patch(
        `/v1/assistant/share/${linkId}`, {}, config
      );
      if (response.status === 200) {
        toast.success(response?.data?.message);
        fetchLinks();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Button variant="text" onClick={onClose} sx={{ minWidth: "auto" }}>
              <ArrowLeft sx={{ width: 32, height: 32, color: "black" }} />
            </Button>
            <h6>Share Assistant Access</h6>
          </Box>
        </Box>
      </Box>

      {/* Scrollable Grid Content */}
      <Box sx={{ overflowY: "auto", maxHeight: "400px" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : links.length === 0 ? (
          // No data scenario
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "#888",
            }}
          >
            <Typography variant="h6">No active links available</Typography>
          </Box>
        ) : (
          <Grid container spacing={1} sx={{ padding: "0 12px" }}>
            {links.map((link) => (
              <Grid item xs={12} key={link.id}>
                <Box
                  sx={{
                    border: "1px solid #E6E6E6",
                    borderRadius: "8px",
                    width: "100%",
                    marginBottom: 1,
                  }}
                >
                  {/* Link Details */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "20px",
                      gap: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px", // Add spacing between expiration and credits
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        <span style={{ fontWeight: 400 }}>Expiration:</span>{" "}
                        {formatDate(new Date(link?.expiration_date))}
                      </Typography>
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        <span style={{ fontWeight: 400 }}>
                          Credits Allotted:
                        </span>{" "}
                        {link?.max_credit_allowed}
                      </Typography>
                    </Box>

                    {/* ToggleButtonGroup for Active/Inactive Status */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ToggleButtonGroup
                        value={link?.is_active ? "Active" : "Inactive"}
                        exclusive
                        onChange={() => handleToggleStatus(link?.id)}
                        aria-label="link status"
                        sx={{ display: "flex" }}
                      >
                        <ToggleButton
                          value="Active"
                          sx={{
                            padding: "4px 16px",
                            "&.Mui-selected": {
                              backgroundColor: "#0a0b1c",
                              color: "#fff",
                              fontSize: "12px",
                            },
                          }}
                        >
                          Active
                        </ToggleButton>
                        <ToggleButton
                          value="Inactive"
                          sx={{
                            padding: "4px 16px",
                            "&.Mui-selected": {
                              backgroundColor: "#0a0b1c",
                              color: "#fff",
                              fontSize: "12px",
                            },
                          }}
                        >
                          Inactive
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Box>

                  <hr
                    style={{
                      border: "1px solid #E6E6E6",
                      width: "100%",
                      margin: "12px 0",
                    }}
                  />

                  {/* Copy Link Section */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 24px 12px 24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <TextField
                          value={link?.link}
                          fullWidth
                          variant="standard"
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              width: "100%",
                              color: "#0A74DA",
                            },
                          }}
                        />
                      </Box>

                      <CopyLinkComponent generatedLink={link?.link} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Back Button */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 2,
          paddingTop: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{ borderColor: "#A9A9A9", color: "black", padding: "8px 24px" }}
          onClick={onClose}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default ManageSharedAccess;
