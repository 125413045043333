import React from "react";
import { Box, Typography, styled } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const ComparisonTable = ({ features, plans }) => {
  const renderValue = (value) => {
    if (typeof value === "boolean") {
      return value ? <CheckIcon sx={{ color: "success.main" }} /> : null;
    }
    return (
      <Typography variant="body2" color="text.secondary">
        {value}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        mx: "auto",
        mt: 4,
        mb: 6, // Added bottom margin
        px: 3, // Reduced horizontal padding
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        align="center"
        sx={{
          mb: 6,
          fontWeight: "bold",
          fontSize: "1.5rem",
        }}
      >
        Compare Plans
      </Typography>

      <Box
        sx={{
          borderRadius: 1,
          border: "1px solid",
          borderColor: "divider",
          overflow: "visible", // This maintains border radius
        }}
      >
        <Box
          sx={{
            position: "relative",
            overflow: "visible", // This enables scrolling while keeping sticky header
            maxHeight: "100%",
          }}
        >
          <StickyHeader>
            <Box className="header-spacer" />
            {plans.map((plan) => (
              <Box key={plan} className="header-plan">
                <PlanHeader>
                  <Typography variant="subtitle1">{plan}</Typography>
                </PlanHeader>
              </Box>
            ))}
          </StickyHeader>
          {Object.entries(features).map(([category, items]) => (
            <Box key={category}>
              <FeatureCategory>
                <Typography variant="subtitle1">{category}</Typography>
              </FeatureCategory>

              {items.map((feature, index) => (
                <FeatureRow key={feature.name} alternate={index % 2 === 0}>
                  <Box className="feature-name">
                    <Typography variant="body2">{feature.name}</Typography>
                  </Box>
                  {plans.map((plan) => (
                    <Box
                      key={`${feature.name}-${plan}`}
                      className="feature-value"
                    >
                      {feature.plans[plan] && feature.plans[plan] === -1
                        ? renderValue("Custom")
                        : renderValue(feature.plans[plan])}
                    </Box>
                  ))}
                </FeatureRow>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ComparisonTable;

// Styled Components
const PlanHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "#F4F1FE",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  textAlign: "center",
  "& .MuiTypography-root": {
    color: "#0D0B45",
    fontWeight: 500,
  },
}));

const FeatureCategory = styled(Box)(({ theme }) => ({
  borderTop: "1px solid #E9E4FF",
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  "& .MuiTypography-root": {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const FeatureRow = styled(Box)(({ theme, alternate }) => ({
  display: "flex",
  backgroundColor: alternate ? "#F9FAFB" : "#FFFFFF",
  "& .feature-name": {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    minWidth: 300,
    flex: "0 0 25%",
  },
  "& .feature-value": {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
}));

const StickyHeader = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: "#fff",
  zIndex: 1000,
  borderColor: theme.palette.divider,
  display: "flex",
  "& .header-spacer": {
    flex: "0 0 25%",
    minWidth: 300,
    padding: theme.spacing(2),
  },
  "& .header-plan": {
    flex: 1,
    padding: theme.spacing(2),
  },
}));
