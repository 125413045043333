import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';
export const CustomRadarChart = ({ data }) => {
  const formattedData = data.map(item => ({
    ...item,
    name: item.name === "Call Abandonment" ? "Call Abandonment Rate" : item.name,
    value: parseFloat(item.value).toFixed(2) // Ensures proper decimal formatting
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={formattedData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" fontSize={13} />
        <PolarRadiusAxis angle={40} />
        <Radar dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        <Tooltip />
      </RadarChart>
    </ResponsiveContainer>
  );
};
