import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  Modal,
  Pagination,
} from "@mui/material";
import {
  CallRounded,
  Add,
  Close,
  Dashboard,
  AccessTime,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Title from "../components/Title";
import AssistantCard from "../components/AssistantCard";
import AssistantTemplate from "../components/AssistantForms/AssistantTemplate";
import Loader from "../components/Loader";
import NoDataFound from "../components/NoDataFound";
import api from "../api";
import "../styles/assistants.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const NewAssistants = () => {
  const [searchOption, setSearchOption] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [token, setToken] = useState("");
  const [assistants, setAssistants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingAssistants, setLoadingAssistants] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [organization, setOrganization] = useState({});
  const [permissions, setPermissions] = useState([]);

  const navigate = useNavigate();
  const { company_id } = useParams();

  const searchOptions = [
    { value: "Name", label: "Name" },
    { value: "UpdatedAt", label: "Updated At" },
    { value: "Category", label: "Category" },
  ];

  const default_values={
    Cutoff : 1,
    EndConversationOnGoodbye : 0,
    FilterAudio : "0",
    IdealTime : "15",
    InitialMsg : "Hello",
    Interruption : "Low",
    LLMModel: "67232c107491006fe5bff619",
    LLMProvider : "67232c107491006fe5bff614",
    MaxToken : "1000",
    NumOfHumanCheck: "1",
    Prompt : "Write your Prompt here ...",
    SpeedOfText : 0.4,
    Temperature : 0.3,
    Transcriber : "67232c0f7491006fe5bff5de",
    TranscriberLanguage : "67232c0f7491006fe5bff5f1",
    TranscriberProvider : "67232c0f7491006fe5bff5d6",
    Voice : "67232c117491006fe5bff623",
    VoiceLanguage : "67232c117491006fe5bff635",
    VoiceProvider : "67232c107491006fe5bff621"
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("User"));
    setUserRole(userData?.Role || "");
    setToken(userData?.Token);
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenTemplateModal = () => {
    // New function to open template modal
    setTemplateModalOpen(true);
  };

  const handleCloseTemplateModal = () => {
    // New function to close template modal
    setTemplateModalOpen(false);
  };

  const fetchOrganization = async () => {
    if (!company_id || userRole === "Admin") return;

    try {
      const response = await api.get(`/organizations/${company_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrganization(response.data);
    } catch (error) {
      console.error("Error fetching organization:", error);
    }
  };

  const fetchAssistants = useCallback(
    async (page = 1, search = "") => {
      setLoadingAssistants(true);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        let response;
        if (userRole === "SuperAdmin") {
          response = await api.get(
            `/assistants?limit=9&search=${search}&page=${page}`,
            config
          );
          setAssistants(response.data.data.assistants);
          setTotalPages(response.data.data.totalPages);
          setCurrentPage(response.data.data.currentPage);
        } else {
          // For admin, fetch both company assistants and demo assistants if ShowDemo is true
          // const demoResponse = organization?.ShowDemo ?
          //     await api.post("/assistants/demo-assistants", {}, {
          //         ...config,
          //         params: { page }
          //     }) : { data: { data: { demoAssistants: [] } } };

          const companyResponse = await api.post(
            "/organizations/assistants-by-company",
            {},
            {
              ...config,
              params: { page },
            }
          );

          const combinedAssistants = [
            ...companyResponse.data.data,
            // ...(demoResponse.data.data?.demoAssistants || [])
          ];

          setAssistants(combinedAssistants);
          setTotalPages(
            Math.max(
              companyResponse.data?.totalPages || 0
              // demoResponse.data?.totalPages || 0
            )
          );
        }
      } catch (error) {
        console.error("Error fetching assistants:", error);
      } finally {
        setLoadingAssistants(false);
      }
    },
    [token, userRole]
  );

  useEffect(() => {
    if (token) {
      fetchOrganization();
      getAllPermissions();
    }
  }, [token]);

  useEffect(() => {
    if (token && (userRole === "SuperAdmin" || organization)) {
      fetchAssistants(currentPage, searchValue);
    }
  }, [
    token,
    userRole,
    organization,
    fetchAssistants,
    currentPage,
    searchValue,
  ]);

  const handleSearchChange = (event) => {
    const newSearchValue = event.target.value;
    setSearchValue(newSearchValue);
    fetchAssistants(1, newSearchValue);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchAssistants(value, searchValue); // Explicitly call fetchAssistants for new page
  };

  const handleCreateAssistant = () => {
    localStorage.removeItem("assistant");
    localStorage.removeItem("KnowledgeBase");
    localStorage.removeItem("Logo");
    localStorage.setItem("assistant",JSON.stringify(default_values))
    navigate(`/company/${company_id}/assistants/create`);
  };

  // Function to fetch permissions for the user
  const getAllPermissions = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await api.get(`/v1/users/permissions/`, config);
      setPermissions(response.data.permissions); // Set permissions state
      localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  if (loading) return <Loader />;


  return (
    <Box>
      {permissions &&
      permissions.includes("fetch_assistant") &&
      permissions.includes("search_assistant") ? (
        <Navbar
          title="Assistants"
          searchOptions={searchOptions}
          searchOption={searchOption}
          searchValue={searchValue}
          onSearchOptionChange={(e) => setSearchOption(e.target.value)}
          onSearchValueChange={handleSearchChange}
        />
      ) : (
        <Navbar title="Assistants" />
      )}

      <Box p={2}>
        {permissions && permissions.includes("create_assistant") && (
          <>
            <Title
              title="Create Assistant"
              description="Start from scratch and build your AI assistant"
            />

            <Card
              className="color-prim-cetacean-blue-600"
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                borderRadius: "1rem",
              }}
            >
              <CardContent className="assistantCardDetails">
                <Box className="boxHeading">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: "#353657",
                        borderRadius: "50%",
                        padding: "0.5rem",
                      }}
                    >
                      <CallRounded
                        style={{ color: "#fff", marginBottom: "-0.5rem" }}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Real-Time Voice Assistant
                      </Typography>
                      <Typography variant="body2">
                        Personalized AI assistant available for you 24/7
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    style={{
                      color: "#02042d",
                      backgroundColor: "#fff",
                      textTransform: "capitalize",
                      borderRadius: ".6rem",
                    }}
                    onClick={handleOpenModal}
                  >
                    <Add /> Create Assistant
                  </Button>
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem", mt: "1.5rem" }}>
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <CallRounded
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="Inbound"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <CallRounded
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="Outbound"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                  <Chip
                    icon={
                      <Box
                        style={{
                          display: "grid",
                          placeItems: "center",
                          backgroundColor: "#353657",
                          borderRadius: "50%",
                          padding: 4,
                        }}
                      >
                        <AccessTime
                          style={{ fontSize: "1rem", color: "#fff" }}
                        />
                      </Box>
                    }
                    label="24/7 Uptime"
                    variant="outlined"
                    sx={{
                      borderColor: "#353657",
                      borderWidth: "2px",
                      color: "white",
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </>
        )}

        {loadingAssistants ? (
          <Loader /> // Show loader only for assistants
        ) : permissions && permissions.includes("fetch_assistant") ? (
          assistants.length > 0 ? (
            <>
              <Title
                title="All Assistants"
                description="See all your existing assistants and customize all the values according to your need"
              />
              <Grid
                container
                spacing={2}
                mt={1}
                mb={2}
                sx={{ overflow: "hidden" }}
              >
                {assistants.map((assistant) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={assistant._id}>
                    <AssistantCard
                      name={assistant.Name}
                      category={assistant.Category}
                      description={assistant.Description || "No description"}
                      Type={assistant.Type}
                      lastUpdated={assistant.UpdatedAt}
                      fileUrl={assistant.Logo?.fileUrl || "defaultLogo.png"}
                      buttonAction={{
                        label: "View Assistant",
                        url:
                          userRole === "SuperAdmin"
                            ? `/assistants/${assistant._id}`
                            : `/company/${company_id}/assistant/${assistant._id}`,
                      }}
                      assistant={assistant}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="#02042D"
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "70dvh",
              }}
            >
              <NoDataFound
                title="No Assistant Found"
                subtitle="There is no assistant found to show the data right now."
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "70dvh",
            }}
          >
            <NoDataFound
              title="Insufficient Permissions"
              subtitle="You do not have permission to view assistants."
            />
          </Box>
        )}
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="create-assistant-modal-title"
        aria-describedby="create-assistant-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 700,
            height: 400,
            borderRadius: "1rem",
            backgroundColor: "#FAFAFA",
            overflow: "hidden",
            padding: "1.2rem",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Create Assistant</Typography>
            <Button onClick={handleCloseModal} style={{ minWidth: "auto" }}>
              <Close sx={{ color: "#8A8A8A" }} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              mt: "1.5rem",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography variant="h6">Choose Preference</Typography>
              <Typography variant="p">
                Pick your starting point for assistant
              </Typography>
            </Box>

            <Box
              mt={2}
              style={{
                display: "flex",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <Card
                onClick={handleOpenTemplateModal}
                sx={{
                  cursor: "pointer",
                  boxShadow: 0,
                  border: "1px solid #B0B0B0",
                  borderRadius: "12px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <Box
                    style={{
                      width: "2rem",
                      height: "2rem",
                      backgroundColor: "#02042d",
                      borderRadius: "50%",
                      padding: "1.5rem 1.5rem 1.5rem 1.5rem",
                      position: "relative",
                    }}
                  >
                    <Dashboard
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" style={{ color: "#02042d" }}>
                    Template Library
                  </Typography>
                  <Typography variant="p">
                    Get start with predefined assistants
                  </Typography>
                </CardContent>
              </Card>
              <Card
                onClick={handleCreateAssistant}
                sx={{
                  cursor: "pointer",
                  boxShadow: 0,
                  border: "1px solid #B0B0B0",
                  borderRadius: "12px",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <Box
                    style={{
                      width: "2rem",
                      height: "2rem",
                      backgroundColor: "#02042d",
                      borderRadius: "50%",
                      padding: "1.5rem 1.5rem 1.5rem 1.5rem",
                      position: "relative",
                    }}
                  >
                    <Add
                      style={{
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </Box>
                  <Typography variant="h6" style={{ color: "#02042d" }}>
                    Create from Scratch
                  </Typography>
                  <Typography variant="p">
                    Get start with predefined assistants
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={templateModalOpen}
        onClose={handleCloseTemplateModal}
        aria-labelledby="template-library-modal-title"
        aria-describedby="template-library-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: "80dvw",
            height: "80dvh",
            padding: "2rem",
            borderRadius: "1rem",
            overflow: "auto",
          }}
        >
          <Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" id="template-library-modal-title">
                Template Library
              </Typography>
              <Button
                onClick={handleCloseTemplateModal}
                style={{ minWidth: "auto" }}
              >
                <Close />
              </Button>
            </Box>
            <Typography variant="body1">
              Here you can choose from predefined assistants to help you get
              started.
            </Typography>
          </Box>

          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
              gap: "1rem",
              margin: "1rem 0",
            }}
          >
            <AssistantTemplate
              name="Alex - Sales Assistant"
              type="Inbound"
              category="Sales"
            />

            <AssistantTemplate
              name="Emma - Customer Support Assistan"
              type="Outbound"
              category="Customer Support"
            />

            <AssistantTemplate
              name="Jake - IT Helpdesk Assistant"
              type="Inbound"
              category="IT Support"
            />

            <AssistantTemplate
              name="Sophie - HR Assistant"
              type="Outbound"
              category="Human Resources"
            />

            <AssistantTemplate
              name="Ethan - Finance Assistant"
              type="Inbound"
              category="Finance"
            />

            <AssistantTemplate
              name="Grace - Appointment Booking Assistant"
              type="Outbound"
              category="Healthcare / Service Industry"
            />

            <AssistantTemplate
              name="Liam - Retail Support Assistant"
              type="Inbound"
              category="Retail"
            />

            <AssistantTemplate
              name=" Insurance Claim Assistant"
              type="Outbound"
              category="Insurance"
            />

            <AssistantTemplate
              name="Oliver - Logistics Support Assistant"
              type="Outbound"
              category="Logistics and Supply Chain"
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default NewAssistants;
