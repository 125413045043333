import api from "../api";

const token = JSON.parse(localStorage.getItem("token"))?.token;

export async function downloadCallAnalysisReport({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/call-analysis", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "call_analysis_report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadAvgCallMinutesByAssistantCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/avg-call-minutes-by-assistant", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "avg-call-minutes-by-assistant.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadFirstCallResolutionPerAssistantCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/first-call-resolution-per-assistant", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "first-call-resolution-per-assistant.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}
//
export async function downloadActionsByAssistantCSV({ filter, organization, assistant }) {
    try {
        // Build query parameters
        const params = new URLSearchParams({ filter });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        // Make API call
        const response = await api.get("/download/actions-by-assistant", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Create a link element and trigger the download
        const url = response.data.downloadUrl;
        const link = document.createElement("a");
        link.href = url;
        link.download = "actions-by-assistant.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Error downloading CSV:", error);
        throw error?.response?.data || error.message || "An unknown error occurred";
    }
}


export async function downloadAssistantPerformanceCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/assistant-performance", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "assistant-performancet.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadOrganizationOverviewCSV({ filter, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/organization-overview", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "organization-overview.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadTotalSpentCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/total-spent", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "total-spent.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadAvgDurationByIssueTypeCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("download/avg-duration-by-issue-type", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "avg-duration-by-issue-type.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadCallActionsCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("download/call-actions", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "call-actions.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadFirstCallResolutionCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("download/first-call-resolution", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "first-call-resolution.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadAIToHumanTransferCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("download/ai-to-human-transfer", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "ai-to-human-transfer.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}

export async function downloadPeakHourTrafficCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("download/peak-hour-traffic", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "peak-hour-traffic.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error;
    }
}

export async function downloadWPMMetricsCSV({ filter, organization, assistant }) {
    try {
        const params = new URLSearchParams({
            filter,
        });

        if (organization && organization !== "All") {
            params.append("organizationId", organization);
        }

        if (assistant && assistant !== "All") {
            params.append("assistantId", assistant);
        }

        const response = await api.get("/download/words-per-minute", {
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const url = new URL(response.data.downloadUrl);
        const link = document.createElement("a");
        link.href = url.toString();
        link.download = "words-per-minute.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        throw error.response?.data;
    }
}