import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../components/Navbar'
import AssistantForm from '../components/AssistantForm'
import { useParams } from 'react-router-dom'
import NewAssistantForm from '../components/NewAssistantForm'

const CreateAssistant = () => {
    const { company_id } = useParams()
    return (
        <Box>
            <Navbar
                title="Create Assistant"
            />
            {!company_id ? <AssistantForm /> : <NewAssistantForm />}

        </Box>
    )
}

export default CreateAssistant