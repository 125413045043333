

// // WorkflowsPage.jsx
import React from 'react';

const WorkflowsPage = () => {
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 20px)', padding: '10px' }}>
      <iframe
        src="https://n8n-liaplus-dashboard.liaplus.com/home/workflows"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '8px'
        }}
        title="n8n Workflows"
      />
    </div>
  );
};

export default WorkflowsPage;