import React from 'react';
import { Box, Typography } from '@mui/material';
import { Description, Sync, Delete } from '@mui/icons-material';

const FileDetails = ({ file, onUpdate, onDelete, formatFileSize }) => {
    return (
        <Box
            sx={{
                width: "100%",
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                p: 2,
                bgcolor: "#fff"
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 1 }}>
                    <Description sx={{ fontSize: 27 }} />
                    <Box>
                        <Typography sx={{ fontWeight: 500, color: '#111827' }}>
                            {file.name}
                        </Typography>
                        <Typography variant="caption" sx={{ color: '#6B7280' }}>
                            {formatFileSize(file.size)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <label style={{ cursor: 'pointer' }}>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={onUpdate}
                            accept=".xlsx,.xls,.csv"
                        />
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            color: '#3662E3',
                            '&:hover': { opacity: 0.8 }
                        }}>
                            <Sync sx={{ fontSize: 20 }} />
                            <Typography>Update</Typography>
                        </Box>
                    </label>
                    <Box
                        onClick={onDelete}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            color: '#DC2626',
                            cursor: 'pointer',
                            '&:hover': { opacity: 0.8 }
                        }}
                    >
                        <Delete sx={{ fontSize: 20 }} />
                        <Typography>Remove</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default FileDetails;