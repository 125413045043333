import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, CircularProgress } from '@mui/material';
import InitialDetails from './AssistantForms/InitialDetails';
import Prompt from './AssistantForms/Prompt';
import TranscriberAndAudio from './AssistantForms/TranscriberAndAudio';
import AdditionalConfigurations from './AssistantForms/AdditionalConfigurations';
import Actions from './AssistantForms/Actions';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleOutline, DashboardOutlined, DescriptionOutlined, RecordVoiceOverOutlined, SettingsOutlined } from '@mui/icons-material';
import api from "../api";

const NewAssistantForm = () => {
    const [ value, setValue ] = useState(0);
    const [ token, setToken ] = useState('');
    const [ assistantId, setAssistantId ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ userRole, setUserRole ] = useState("");
    const [ loading_1, setLoading_1 ] = useState(false)

    const permissions = JSON.parse(localStorage.getItem('permissions'))

    const navigate = useNavigate()
    const { company_id } = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getToken = async () => {
        const userData = JSON.parse(localStorage.getItem("User"));
        setUserRole(userData?.Role || "");
        setToken(userData?.Token);
    };

    useEffect(() => {
        getToken();
    }, []);

    const handleSave = async () => {
        setLoading_1(true);
        try {
            const assistant = JSON.parse(localStorage.getItem("assistant"));
            const logoData = JSON.parse(localStorage.getItem("Logo")); // Logo metadata and content
            const knowledgeBaseData = JSON.parse(localStorage.getItem("KnowledgeBase")); // Logo metadata and content

            const formData = new FormData();
            for (const [ key, value ] of Object.entries(assistant)) {
                formData.append(key, value); // Append each key-value pair
            }

            formData.append("Organization", company_id);

            if (logoData) {
                // Convert Base64 to Blob
                const byteString = atob(logoData.content.split(',')[ 1 ]);
                const mimeString = logoData.content.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[ i ] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ ab ], { type: mimeString });

                // Append the logo file to the formData
                formData.append("logo", new File([ blob ], logoData.name, { type: logoData.type }));
            }
            if (knowledgeBaseData) {
                // Convert Base64 to Blob
                const byteString = atob(knowledgeBaseData.content.split(',')[ 1 ]);
                const mimeString = knowledgeBaseData.content.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[ i ] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ ab ], { type: mimeString });

                // Append the logo file to the formData
                formData.append("knowledgeBase", new File([ blob ], knowledgeBaseData.name, { type: knowledgeBaseData.type }));
            }

            setLoading(true);

            const response = await api.post("/v1/assistant/create", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
            });

            setLoading(false);

            const actions = localStorage.getItem('actions');
            if (actions) {
                const parsedActions = JSON.parse(actions);
                for (const action of parsedActions) {
                    if (!action._id) {
                        action.Assistant = response.data.assistant._id
                        await api.post("/v1/actions/create-action", action, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                    }
                }
            }


            toast.success(response.data.message);
            // navigate('/assistants');
            navigate(
                userRole === "SuperAdmin"
                    ? "/assistants"
                    : `/company/${company_id}/assistants`
            );
        } catch (error) {
            toast.error(error?.response?.data?.details);
            console.log(error);
        } finally {
            setLoading_1(false);
        }
    };

    // Dynamically generate tabs based on permissions
    const tabs = [
        { label: 'Information', component: <InitialDetails assistantId={assistantId} />, icon: <DashboardOutlined sx={{ fontSize: 20 }} /> },
        permissions?.includes("fetch_llm_model") && permissions?.includes("fetch_LLM_providers") && {
            label: 'Prompt',
            component: <Prompt assistantId={assistantId} />,
            icon: <DescriptionOutlined sx={{ fontSize: 20 }} />,
        },
        ((permissions?.includes("get_voice_providers") && permissions?.includes("fetch_VoiceSynthesizers_model") && permissions?.includes("fetch_voicelanguage_by_synthesizerid")) && (permissions?.includes("fetch_voicelanguage_by_synthesizerid") && permissions?.includes("fetch_language_by_transcriberid") && permissions?.includes("fetch_transcribers_provider"))) && {
            label: 'Transcriber and Voice',
            component: <TranscriberAndAudio assistantId={assistantId} />,
            icon: <RecordVoiceOverOutlined sx={{ fontSize: 20 }} />
        },
        { label: 'Configurations', component: <AdditionalConfigurations assistantId={assistantId} />, icon: <SettingsOutlined sx={{ fontSize: 20 }} /> },
        permissions?.includes("create_action") && {
            label: 'Actions',
            component: <Actions assistantId={assistantId} />,
            icon: <CheckCircleOutline sx={{ fontSize: 20 }} />,
        },
    ].filter(Boolean);

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', justifyContent: 'start', mb: 3, backgroundColor: '#02042d', paddingTop: '0.5rem', paddingLeft: '0.5rem', borderRadius: '0.5rem', width: 'fit-content' }}>
                    <Tabs
                        value={value}
                        onChange={(_, newValue) => setValue(newValue)}
                        size="small"
                        style={{ marginBottom: '-0.5rem' }}
                        sx={{
                            '& .MuiTabs-flexContainer': { borderRadius: '8px', overflow: 'hidden', height: '2rem' },
                            '& .MuiTab-root': {
                                textTransform: 'none', padding: '0.25rem .5rem', marginRight: 1,
                                borderRadius: '8px', backgroundColor: '#02042d', color: '#ffffff',
                                minHeight: '1rem', height: '2rem', fontWeight: 'semi',
                                '&.Mui-selected': { backgroundColor: '#353657', color: '#ffffff' },
                                '&:hover': { backgroundColor: '#353657', color: '#ffffff' },
                            },
                            '& .MuiTabs-indicator': { display: 'none' },
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start" />
                        ))}
                    </Tabs>
                </Box>
                <Button
                    variant="contained"
                    style={{
                        color: '#fff',
                        backgroundColor: '#02042d',
                    }}
                    sx={{ marginLeft: 'auto', marginTop: '0.5rem' }}
                    onClick={handleSave}
                    disabled={loading_1}
                >
                    {loading_1 ? (
                        <CircularProgress
                            size={24} // Size of the loader
                            sx={{
                                color: '#fff', // Color of the loader
                            }}
                        />
                    ) : (
                        "Publish"
                    )}
                </Button>
            </Box>

            {tabs[ value ]?.component}
        </Box>
    );
};

export default NewAssistantForm;
