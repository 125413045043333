// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
* {
  font-family: "Causten", sans-serif !important;
  /* Use !important if necessary */
}

.appBar,
.toolBar {
  background-color: #fff !important;
}

.toolBar {
  display: flex;
  justify-content: "space-between";
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* For Firefox */
scrollbar {
  width: 8px;
}

scrollbar-thumb {
  background-color: #aaa;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,6CAA6C;EAC7C,gCAAgC;AAClC;;AAEA;;EAEE,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC;;AAEA,yCAAyC;AACzC;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA,gBAAgB;AAChB;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["\n* {\n  font-family: \"Causten\", sans-serif !important;\n  /* Use !important if necessary */\n}\n\n.appBar,\n.toolBar {\n  background-color: #fff !important;\n}\n\n.toolBar {\n  display: flex;\n  justify-content: \"space-between\";\n}\n\n/* For Webkit browsers (Chrome, Safari) */\n::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #aaa;\n  border-radius: 4px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background-color: #888;\n}\n\n/* For Firefox */\nscrollbar {\n  width: 8px;\n}\n\nscrollbar-thumb {\n  background-color: #aaa;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
