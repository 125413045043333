import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, styled } from "@mui/material";
import Navbar from "../../components/Navbar";
import ComparisonTable from "../../components/Pricing/ComparisonTable";
import PlanCard from "../../components/Pricing/PlanCard";
import api from "../../api";
import Loader from "../../components/Loader";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";

const Pricing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [features, setFeatures] = useState({});
  const [plansName, setPlansName] = useState([]);

  const userData = JSON.parse(localStorage.getItem("User"));
  const planId = localStorage.getItem("planId") || null;
  const token = JSON.parse(localStorage.getItem("token"))?.token;

  const sessionFetched = useRef(false); // Ref to avoid duplicate fetching

  // Extract session_id from query params
  const sessionId = new URLSearchParams(location.search).get("session_id");

  // Fetch all plans on component mount
  useEffect(() => {
    fetchAllPlans();
  }, []);

  // Handle subscription or upgrade logic based on query params
  useEffect(() => {
    if (!sessionFetched.current && sessionId)  {
      sessionFetched.current = true;
      if (sessionId) handleSubscriptionDetails(sessionId);
    }
  }, [sessionId]);

  // Fetch all plans and features
  const fetchAllPlans = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/v1/billing/all-plans");
      setPlans(data?.plans || []);
      setFeatures(data?.features || {});
      setPlansName(data?.plans?.map((plan) => plan.name) || []);
    } catch (error) {
      console.error("Error fetching plans:", error);
      toast.error("Failed to fetch plan details.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle subscription details
  const handleSubscriptionDetails = async (id) => {
    try {
      const { data } = await api.get(`/v1/billing/success?session_id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data?.subscription) {
        toast.success(data?.msg);
        localStorage.setItem("planId", data?.plan_name);
        navigate(`/company/${userData.Organisation}/pricing`, {
          replace: true,
        });
      } else {
        toast.error("Failed to retrieve subscription details.");
      }
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      toast.error("An error occurred while fetching subscription details.");
    }
  };

  const handleUpgrade = async (payload, token) => {
    try {
      return await api.post("/v1/billing/upgrade-subscription", payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      console.error("Error upgrading the plan:", error);
      toast.error("An error occurred while upgrading the plan.");
      return null;
    }
  };

  // Email
  const createEmailLink = (plan) => {
    const subject = encodeURIComponent(`Interest in ${plan.name} Plan`);
    const body = encodeURIComponent(
      `I would like to know more about the ${plan.name} plan.`
    );
    return `mailto:${plan.email}?subject=${subject}&body=${body}`;
  };

  // Handle plan selection and subscription creation
  const handlePlanSelect = useCallback(
    async (planName) => {
      const selectedPlan = plans.find((plan) => plan.name === planName);

      if (!selectedPlan) {
        toast.error("Invalid plan selected.");
        return;
      }

      // Handle Enterprise or already active subscription
      const currentPlanIndex = plans.findIndex((p) => p.name === planId); // Find index of current plan
      const planIndex = plans.findIndex((p) => p.name === planName);
      if (
        (planId && planName === "Enterprise") ||
        planIndex < currentPlanIndex
      ) {
        window.location.href = createEmailLink(selectedPlan);
        return;
      }

      setLoading(true);
      try {
        // Fetch plan details
        const {
          data: { data: planData, msg },
        } = await api.post(
          "/v1/billing/plan-details",
          { plan_name: planName },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!planData) {
          toast.error("Failed to retrieve plan details.");
          return;
        }

        toast.info(msg);

        // Prepare subscription payload
        const payload = {
          ...planData,
          OrganizationId: userData.OrganizationId || userData.Organisation,
        };

        if (!planId) {
          // Create new subscription
          const {
            data: {
              data: { url: subscriptionUrl },
            },
          } = await api.post("/v1/billing/create-subscription", payload, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (subscriptionUrl) {
            window.location.href = subscriptionUrl;
          } else {
            toast.error("Failed to create subscription. Please try again.");
          }
        } else {
          // Upgrade existing subscription
          const {
            data: {
              session: { url: upgradeSubscriptionUrl },
            },
          } = await handleUpgrade(
            { ...payload, currentPlanName: planId },
            token
          );

          if (upgradeSubscriptionUrl) {
            window.location.href = upgradeSubscriptionUrl;
          }
        }
      } catch (error) {
        console.error("Error while subscribing to the plan:", error);
        toast.error("An error occurred while subscribing to the plan.");
      } finally {
        setLoading(false);
      }
    },
    [token, plans, planId, userData.OrganizationId, userData.Organisation]
  );

  return (
    <Box>
      <Navbar title="Plans & Features" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ p: { xs: 3, md: 4 }, bgcolor: "#F8F9FC" }}>
            <PlansContainer>
              {plans.map((plan) => (
                <PlanCard
                  key={plan.name}
                  plan={plan}
                  plans={plans}
                  isActive={planId}
                  onPlanSelect={handlePlanSelect}
                />
              ))}
            </PlansContainer>
          </Box>
          <ComparisonTable features={features} plans={plansName} />
        </>
      )}
    </Box>
  );
};

export default Pricing;

const PlansContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
