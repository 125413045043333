import React, { useState } from 'react';
import { Box, Button, Typography, Modal, Checkbox } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'sonner';
import api from '../../api'; // Ensure this path is correct for your project structure

const ConfirmationModal = ({
    confirmationModalOpen,
    setConfirmationModalOpen,
    consentChecked,
    setConsentChecked,
    previewData,
    scheduleDate,
    scheduleTime,
    setPreviewModalOpen,
    campaignName,
    campaignDescription,
    assistantId,
    scheduleFile,
    phone,
}) => {
    const [isScheduling, setIsScheduling] = useState(false);

    const handleConfirmSchedule = async () => {


        if (!consentChecked) {

            toast.error("Please confirm that you have obtained consent");
            return;
        }



        if (!phone || !phone._id) {
            console.error("Phone object is invalid:", phone);
            toast.error("Invalid phone data. Please try again or contact support.");
            return;
        }

        setIsScheduling(true);

        try {
            const formData = new FormData();
            formData.append('campaignName', campaignName);
            formData.append('campaignDescription', campaignDescription);
            formData.append('scheduleOn', scheduleDate);
            formData.append('selectTime', scheduleTime);
            formData.append('assistantId', assistantId);
            formData.append('associatedPhone', phone._id);

            // Get the file from localStorage
            const storedFile = localStorage.getItem('scheduledFile');
            if (storedFile) {
                const blob = await fetch(storedFile).then(r => r.blob());
                formData.append('recipientFile', blob, scheduleFile.name);
            } else {
                throw new Error('Recipient file is required');
            }


            const token = JSON.parse(localStorage.getItem('token'))?.token;
            if (!token) {
                throw new Error('Authentication token is missing');
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            };


            const response = await api.post("/v1/scheduled-calls/schedule-bulk-call", formData, config);



            if (response.data.success) {

                setConfirmationModalOpen(false);
                toast.success("Bulk call scheduled successfully");
            } else {

                throw new Error(response.data.error || 'Failed to schedule bulk call');
            }
        } catch (error) {
            console.error('Error scheduling bulk call:', error);
            if (error.response) {
                console.error('Error response:', error.response);
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                
                if (error.response.status === 401) {
                    console.error('Unauthorized access. Token might be invalid or expired.');
                    toast.error("Your session has expired. Please log in again.");
                } else {
                    toast.error(error.response.data.error || "Failed to schedule bulk call");
                }
            } else if (error.request) {
                console.error('Error request:', error.request);
                toast.error("No response received from server. Please try again.");
            } else {
                console.error('Error message:', error.message);
                toast.error(error.message || "An error occurred while scheduling the bulk call");
            }
        } finally {

            setIsScheduling(false);
        }
    };
    const contactCount = previewData ? previewData.length - 1 : 0;
    const formattedDate = new Date(scheduleDate).toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    }).replace(/\//g, '/');

    return (
        <Modal 
            open={confirmationModalOpen} 
            onClose={() => !isScheduling && setConfirmationModalOpen(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ 
                width: "570px",
                bgcolor: "#FFFFFF",
                borderRadius: "16px",
                p: 4,
                outline: 'none',
            }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
                    Schedule Call to Reach Contacts ?
                </Typography>

                <Box sx={{ 
                    bgcolor: "#F8F9FA",
                    borderRadius: "8px",
                    p: 2,
                    mb: 3,
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                }}>
                    <ErrorIcon sx={{ color: '#6C757D', mt: 0.5 }} />
                    <Typography variant="body1" sx={{ color: '#495057' }}>
                        Upon confirmation, your campaign will be launched, and calls will be scheduled with <strong>{contactCount} contacts</strong> starting on <strong>{formattedDate}</strong> at <strong>{scheduleTime}</strong>.
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 3 }}>
                    <Checkbox
                        checked={consentChecked}
                        onChange={(e) => setConsentChecked(e.target.checked)}
                        disabled={isScheduling}
                        sx={{ 
                            '& .MuiSvgIcon-root': { fontSize: 28 },
                            color: '#6C757D',
                            '&.Mui-checked': {
                                color: '#0056b3',
                            },
                        }}
                    />
                    <Typography variant="body2" sx={{ color: '#495057' }}>
                        By checking this box, I confirm that I have obtained explicit consent from all contacts I import for outreach via automated or pre-recorded voice calls. I also agree to comply with all relevant regulations and guidelines under the <strong>Indian Telecom Commercial Communications Customer Preference Regulations (TCCCPR)</strong> and other applicable laws in my communications with these contacts.
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button 
                        variant="outlined" 
                        onClick={() => {
                            setConfirmationModalOpen(false);
                            setPreviewModalOpen(true);
                        }}
                        disabled={isScheduling}
                        sx={{ 
                            color: '#495057',
                            borderColor: '#CED4DA',
                            '&:hover': {
                                borderColor: '#ADB5BD',
                                bgcolor: 'rgba(206, 212, 218, 0.1)',
                            },
                        }}
                    >
                        Go Back
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleConfirmSchedule}
                        disabled={!consentChecked || isScheduling}
                        sx={{ 
                            bgcolor: "#02042D", 
                            color: "white",
                            '&:hover': { bgcolor: "#0a0b3b" },
                            '&:disabled': { bgcolor: "#6C757D", color: "white" },
                        }}
                    >
                        {isScheduling ? 'Scheduling...' : 'Confirm'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;

