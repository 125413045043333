import api from "../../api";
import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Avatar, Box, Chip, Divider, Drawer, FormControl, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { AnalyticsOutlined, Assessment, AssignmentTurnedInOutlined, Assistant, AttachMoneyOutlined, Call, CardMembership, CheckCircleOutline, CodeOutlined, DescriptionOutlined, Group, InfoOutlined, ListAltOutlined, RecordVoiceOverOutlined, SearchOutlined, VolumeOffOutlined } from '@mui/icons-material';
import { TimeStamp } from '../../components/TimeStamp';
import WaveformPlayer from '../../components/Wave';
import ClipboardBox from '../../components/ClipboardBox';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { CustomLabel } from "../../components/Custome";
import { COLORS } from "../../components/Constatnt";
import { convertToMinutes, getCallCost } from "../../components/formate";


const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [tab, setTab] = useState("info");
    const [tabData, setTabData] = useState({});
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const { company_id } = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const getCallLogs = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get("/call-logs/all-call-logs", {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                params: {
                    organizationId: company_id,
                    page: page + 1,
                    limit: rowsPerPage,
                    search: debouncedSearch,
                    filter: filter,
                }
            });

            setLogs(response.data.data);
            setTotalPages(response.data?.pages || 0);
            setTotalRows(response.data?.total || 0);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [token, company_id, page, rowsPerPage, debouncedSearch, filter]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedSearch(search);
        }, 1000);

        return () => clearTimeout(debounceTimeout);
    }, [search]);

    useEffect(() => {
        if (token && debouncedSearch !== null) {
            getCallLogs();
        }
    }, [getCallLogs, debouncedSearch, token]);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Navbar title={"Logs"} />
            <Box style={{ display: "flex", flexDirection: "column" }}>

                <Box sx={{ padding: "2rem" }}>
                    <Box>
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Search..."
                                label="Search"
                                value={search}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleSearch}
                                sx={{ width: "20rem" }}
                            />

                            <FormControl
                                sx={{
                                    display: 'flex', 
                                    justifyContent: 'center'
                                }} 
                                size="small"
                            >
                                <InputLabel id="filter">Filter</InputLabel>
                                <Select
                                    labelId="filter"
                                    id="filter"
                                    label="Filter"
                                    sx={{ width: '10rem' }}
                                    size="small"
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <MenuItem value="today">Today</MenuItem>
                                    <MenuItem value="yesterday">Yesterday</MenuItem>
                                    <MenuItem value="last7days">Last 7 Days</MenuItem>
                                    <MenuItem value="last30days">Last 30 Days</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                    <Box sx={{ marginTop: "2rem" }}>
                        <TableContainer sx={{ bgcolor: "rgba(177, 177, 190, 0.15)", borderRadius: "5px", border: "1px solid #EEE", boxShadow: "none" }}>
                            <Table sx={{ boxShadow: "none" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Assistant
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Call Id
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Type
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Call Cost
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Ended Reason
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Phone Number
                                        </TableCell>
                                        <TableCell sx={{ color: "#02042D", fontSize: "1rem" }}>
                                            Call Duration
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {logs.length > 0 ? logs.map((data, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => { setOpen(true); setTabData(data) }}
                                        >
                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: 'center', gap: '.5rem' }}>
                                                    <Avatar
                                                        alt={data.AssistantDetails}
                                                        src={data.Assistant?.Logo?.fileUrl}
                                                        sx={{ width: 30, height: 30 }}
                                                    />
                                                    <Box>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: "600", margin: 0 }} variant="body2" component="p">
                                                            {data.AssistantDetails}
                                                        </Typography>

                                                        <Typography variant="caption">
                                                            {data.Type}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <ClipboardBox id={data.Assistant?._id} />
                                            </TableCell>
                                            <TableCell>{data.Type}</TableCell>
                                            <TableCell>${getCallCost(data.Duration)}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={data.CallEndedReason ? data.CallEndedReason : "Not Available"}
                                                    color="default"
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>{data.PhoneNumber}</TableCell>
                                            <TableCell>
                                                {data.Duration ? convertToMinutes(data.Duration) : 0}
                                            </TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow>
                                            <TableCell>
                                                No Data Found
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                component="div"
                                count={totalRows}
                                page={page}
                                onPageChange={(e, newpage) => { setPage(newpage) }}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(1) }}
                            />
                        </TableContainer>
                    </Box>
                </Box>

                {tabData && (
                    <Drawer open={open} onClose={() => setOpen(false)} anchor="right" sx={{ overflowY: "scroll", minHeight: "110vh" }}>
                        <Box sx={{ width: "600px", padding: "1rem 1.5rem", minHeight: "110vh" }}>
                            <Typography variant="h6">Call Log Details</Typography>
                            <Divider orientation='horizontal' sx={{ margin: "1rem 0" }} />

                            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
                                <Typography variant="subtitle2">Detailed Information</Typography>

                                {tabData.Recording ?
                                    <WaveformPlayer audioUrl={tabData.Recording} />
                                    :
                                    <Box style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                                        <VolumeOffOutlined />
                                        Recording not available
                                    </Box>
                                }

                                <Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", bgcolor: "#020429", padding: "6px", borderRadius: "8px" }}
                                    >

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "info" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                            onClick={() => setTab("info")}
                                        >
                                            <Assessment />
                                            <span style={{ fontWeight: "500" }}>Info</span>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Logs" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                            onClick={() => setTab("Logs")}
                                        >
                                            <Assistant />
                                            <span style={{ fontWeight: "500" }}>Logs </span>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Transcript" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                            onClick={() => setTab("Transcript")}
                                        >
                                            <Call />
                                            <span style={{ fontWeight: "500", textWrap: "nowrap" }}>
                                                Transcript
                                            </span>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Analysis" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                            onClick={() => setTab("Analysis")}
                                        >
                                            <Group />
                                            <span style={{ fontWeight: "500" }}>Analysis</span>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px", bgcolor: tab === "Cost" && "#353657", color: "#FFF", padding: "5px 8px", borderRadius: "8px", cursor: "pointer" }}
                                            onClick={() => setTab("Cost")}
                                        >
                                            <Group />
                                            <span style={{ fontWeight: "500" }}>Cost</span>
                                        </Box>

                                    </Box>

                                    {tab === "info" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                                            <InfoOutlined />
                                            <Typography variant="subtitle2">Info</Typography>
                                        </Box>

                                        <Table sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: "none"
                                            }
                                        }}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Assistant
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
                                                        >
                                                            <Avatar
                                                                src={tabData.Assistant?.Logo?.fileUrl}
                                                                alt={tabData.AssistantDetails}
                                                                sx={{ width: 30, height: 30 }}
                                                            />
                                                            <Box>
                                                                <Typography sx={{ fontSize: "16px", fontWeight: "600", margin: 0 }} variant="body2">
                                                                    {tabData.AssistantDetails}
                                                                </Typography>
                                                                <Typography variant="caption">
                                                                    Real state - {tabData.Type}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Call Id
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            <ClipboardBox id={tabData._id} />
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Type
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="inherit" sx={{ textTransform: "capitalize", color: "gray" }}>
                                                            {tabData.Type}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" sx={{ textTransform: "capitalize", color: "gray" }}>
                                                            Status
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={"Completed"}
                                                            variant="outlined"
                                                            sx={{ textTransform: "capitalize", bgcolor: '#020429', color: 'white'}}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Phone Number
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            {tabData.PhoneNumber ? tabData.PhoneNumber : "NA"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Call Duration
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            {convertToMinutes(tabData.Duration)} 
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Ended Reason
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={tabData.CallEndedReason ? tabData.CallEndedReason : "NA"}
                                                            variant="filled"
                                                            sx={{ textTransform: "capitalize", bgcolor: '#020429', color: 'white' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>

                                    </Box>)}

                                    {tab === "Logs" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                        <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                            <ListAltOutlined />
                                            <Typography variant="subtitle2">Logs (Comming Soon)</Typography>
                                        </Box>

                                        <Table sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: "none"
                                            }
                                        }}>
                                            <TableBody>
                                                {tabData.Logs.length > 0 && tabData.Logs.map((log) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {log.timestamp}
                                                        </TableCell>
                                                        <TableCell>{log.event}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>)}

                                    {tab === "Transcript" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                        <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                            <CardMembership />
                                            <Typography variant="subtitle2">Transcipt</Typography>
                                        </Box>

                                        <Box sx={{ marginTop: "1rem" }}>
                                            {tabData.Transcript.length ? tabData.Transcript.map((transcript, index) => {
                                                // Get the key and value from the transcript object
                                                const key = Object.keys(transcript)[0]; // Get the first key
                                                const value = transcript[key]; // Get the corresponding value

                                                return (
                                                    <React.Fragment key={index}>
                                                        <Typography variant="caption">
                                                            {key}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {value}
                                                        </Typography>
                                                    </React.Fragment>
                                                );
                                            })
                                                : <Typography variant="body">Not Available</Typography>

                                            }
                                        </Box>
                                    </Box>)}

                                    {tab === "Analysis" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                        <Box sx={{ display: 'flex', gap: '.5rem' }}>
                                            <AnalyticsOutlined />
                                            <Typography variant="subtitle2">Analysis</Typography>
                                        </Box>
                                        <Table sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                borderBottom: "none"
                                            }
                                        }}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Analysis
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="inherit">
                                                            {tabData.Summary ? tabData.Summary : "NA"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Goal Achievement
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                        style={{textTransform: 'capitalize'}}
                                                            label={tabData.GoalAchievement ? tabData.GoalAchievement : "NA"}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Follow-up
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell style={{textTransform: 'capitalize'}}>
                                                        {tabData.FollowUp ? tabData.FollowUp : "NA"}
                                                    </TableCell>
                                                </TableRow>

                                                <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                                    <AssignmentTurnedInOutlined sx={{ fontSize: "1rem" }} />
                                                    <Typography variant="body1" sx={{ textWrap: "nowrap", color: "#02042D" }}>
                                                        Success Evaluation
                                                    </Typography>
                                                </Box>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Transfer Status
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={tabData.CallTransferred ? "Transferred" : "Not Transferred"}
                                                            variant="filled"
                                                            sx={{ bgcolor: "#02042D", color: "white" }}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Resolution Time
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {tabData.ResolutionTime ? convertToMinutes(tabData.ResolutionTime) : "0 second"}
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            User Satisfaction
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={tabData.UserSatisfaction}
                                                            variant="outlined"
                                                            sx={{ bgcolor: "#02042D", color: "white", textTransform: 'capitalize' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="inherit" color='gray'>
                                                            Script Adherence
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            tabData.ScriptAdherence ? tabData.ScriptAdherence+"%" : '80%'
                                                        }
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Box>)}

                                    {tab === "Cost" && (<Box sx={{ display: "flex", flexDirection: "column", marginTop: "1rem", padding: "1rem", bgcolor: "rgba(177, 177, 190, 0.12)", borderRadius: "1rem" }}>
                                        <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                            <AttachMoneyOutlined />
                                            <Typography variant="subtitle2">Call Cost</Typography>
                                        </Box>

                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "1rem" }}>
                                            <Typography variant="h6" style={{ fontSize: "0.6rem" }}>
                                                Total Cost - $
                                                {
                                                    tabData.TotalCost ?
                                                        <>
                                                            {getCallCost(tabData.Duration)}
                                                        </> :
                                                        "0 second"
                                                }
                                            </Typography>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                    <DescriptionOutlined sx={{ fontSize: 16, color: "#FFBC28" }} />
                                                    <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                        Transcipt
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                    <RecordVoiceOverOutlined sx={{ fontSize: 16, color: "#008AFF" }} />
                                                    <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                        Voice
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                    <CodeOutlined sx={{ fontSize: 16, color: "#FF8045" }} />
                                                    <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                        LLM
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                                                    <CheckCircleOutline sx={{ fontSize: 16, color: "#5DCC8C" }} />
                                                    <Typography variant="inherit" sx={{ fontWeight: 500, color: "#02042D" }}>
                                                        Action
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Typography style={{textAlign: 'center', fontSize: '1.4rem', marginTop: '3rem'}}>Talk more, pay less! Enjoy calls at an unbeatable $0.01/min for a limited time - don't miss out!</Typography>
                                        <ResponsiveContainer width="100%" height={300}>
                                            <PieChart width={400} height={200}>
                                                <Pie
                                                    data={[
                                                        { name: "Transcription Cost", value: tabData.TranscriptionCost },
                                                        { name: "Voice Cost", value: tabData.Voice },
                                                        { name: "LLM Cost", value: tabData.LLMCost },
                                                        { name: "Action Cost", value: tabData.ActionCost }
                                                    ]}
                                                    cx="50%"
                                                    cy="50%"
                                                    labelLine={false}
                                                    label={<CustomLabel />}
                                                    outerRadius={100}
                                                    dataKey="value"
                                                >
                                                    {COLORS.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip wrapperStyle={{ zIndex: 99999 }} formatter={(value) => `$${value.toFixed(2)}`} />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Box>)}
                                </Box>
                            </Box>
                        </Box >
                    </Drawer >
                )}
            </Box>

        </>
    )
}

export default Logs;
