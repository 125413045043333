import {
  Call,
  Visibility,
  VisibilityOff,
  Error,
  ErrorOutline,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import api from "../api";
import WebCall from "./WebCall";

const SharedWebCall = () => {
  const { uuid } = useParams();
  const [password, setPassword] = useState("");
  const [isPasswordRequired, setIsPasswordRequired] = useState(false); // Assuming password is required
  const [maxCredit, setMaxCredit] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
  const [credit, setCredit] = useState(0);
  const [assistantConfig, setAssistantConfig] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notAValidLink, setNotAValidLink] = useState(false);

  useEffect(() => {
    if (uuid) {
      fetchLinkDetails();
    }
  }, [uuid]);

  const fetchLinkDetails = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/v1/assistant/share/link/${uuid}`);
      setIsPasswordRequired(data?.password_required);
      setMaxCredit(data?.max_credit_allowed);
    } catch (error) {
      console.log("error", error);
      setNotAValidLink(true);
    } finally {
        setLoading(false)
    }
  };
  const handleWebCall = async () => {
    if (maxCredit <= 0) {
      toast.error("No credit available");
      return;
    }

    try {
      setLoading1(true);
      const response = await api.post("/v1/assistant/initiate-web-call", {
        uuid,
        password,
      });
      setAssistantConfig(response.data.data);
      setIsModalOpen(true);
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to initiate call");
    } finally {
      setLoading1(false);
    }
  };

  const handleSubmitPassword = () => {
    if (!password.trim()) {
      setError("Password is required");
      return;
    }
    setError(""); // Clear any previous error
    handleWebCall();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal when "End Call" is clicked
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  if (notAValidLink) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <ErrorOutline sx={{ fontSize: 96, color: "error.main", mb: 3 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Oops! Link Not Found
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 4, textAlign: "center" }}
        >
          The link you’re trying to access is either invalid or has expired.
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ mt: 4 }}>
          Error Code: 404
        </Typography>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
          width: "15%",
        }}
      >
        {isPasswordRequired && (
          <TextField
            size="small"
            fullWidth
            type={showPassword ? "text" : "password"}
            placeholder="Enter password to start call"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ bgcolor: "white" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={togglePasswordVisibility}
                    edge="end"
                    sx={{ color: "#02042d", padding: 0.5 }}
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ fontSize: "18px" }} />
                    ) : (
                      <Visibility sx={{ fontSize: "18px" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        {error && (
          <span
            style={{
              color: "#d32f2f",
              fontSize: "15px",
              marginRight: "35%",
              width: "70%",
              marginTop: "-20px",
              display: "flex",
              alignItems: "center", // Ensures proper vertical alignment
              justifyContent: "center", // Centers the content horizontally
              gap: "4px", // Adds spacing between the icon and text
            }}
          >
            <Error sx={{ fontSize: "15px" }} />
            {error}
          </span>
        )}
        <button
          onClick={isPasswordRequired ? handleSubmitPassword : handleWebCall}
          style={{
            backgroundColor: "#02042d",
            color: "white",
            border: "none",
            borderRadius: "8px",
            padding: "8px 24px",
            cursor: loading ? "not-allowed" : "pointer",
            minWidth: "160px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            fontSize: "14px",
          }}
        >
          {loading1 ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <>
              <Call />
              Try Web Call
            </>
          )}
        </button>
      </Box>

      {isModalOpen && (
        <WebCall
          open={isModalOpen}
          onClose={handleCloseModal}
          wsUrl={`${process.env.REACT_APP_VOICE_URL}`}
          callConfig={assistantConfig}
        />
      )}
    </Box>
  );
};

export default SharedWebCall;
