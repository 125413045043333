import React, { useState } from 'react';
import { Box, Typography, IconButton, Snackbar } from '@mui/material';
import { Link } from '@mui/icons-material';

const CopyLinkComponent = ({ generatedLink }) => {
  const [copied, setCopied] = useState(false); // State to track if the link is copied
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to manage Snackbar visibility

  // Handle copying the link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(generatedLink); // Copy the link to clipboard
    setCopied(true); // Set copied state to true
    setOpenSnackbar(true); // Show the Snackbar with the copied message

    // Hide the Snackbar and reset copied state after 2 seconds
    setTimeout(() => {
      setOpenSnackbar(false);
      setCopied(false); // Reset the copied text back to "Copy Link"
    }, 2000);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 2,
        width: 'auto',
        cursor: 'pointer',
      }}
      onClick={handleCopyLink} // Handle click for both text and button
    >
      <Typography
        sx={{
          color: '#0A74DA',
          fontSize: '14px',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          transition: 'color 0.3s ease', // Transition for text color on hover
        }}
      >
        {copied ? 'Link copied!' : 'Copy Link'}
      </Typography>
      <IconButton
        sx={{
          color: '#0A74DA',
          transition: 'color 0.3s ease',
        }}
      >
        <Link />
      </IconButton>

      {/* Snackbar to show "Link copied!" */}
      <Snackbar
        open={openSnackbar}
        message="Link copied!"
        autoHideDuration={2000} // Auto hide after 2 seconds
      />
    </Box>
  );
};

export default CopyLinkComponent;
