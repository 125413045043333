import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Modal, Button, Checkbox } from '@mui/material';
import { UploadFile, Sync, Description, Close } from '@mui/icons-material';
import HazardIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { toast } from 'sonner';
import api from "../../api";

const KnowledgeBaseUpload = ({ existingFile, onFileUpload, onFileDelete, assistantId }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [consentChecked, setConsentChecked] = useState(false);
    const [maxFileSize, setMaxFileSize] = useState(30); // Default 30MB
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);
    const token = JSON.parse(localStorage.getItem("token"))?.token;
    const fileInputRef = useRef(null);

    useEffect(() => {
        fetchMaxFileSize();
    }, []);

    const fetchMaxFileSize = async () => {
        setIsLoadingConfig(true);
        try {
            const response = await api.post('v1/users/permissions/getconfig', {
                configKeys: ['max_knowledgebase_size']
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.success && response.data.data.max_knowledgebase_size) {
                const sizeInMB = parseInt(response.data.data.max_knowledgebase_size);
                setMaxFileSize(sizeInMB);
            }
        } catch (error) {
            console.error('Error fetching max file size:', error);
            // Keep using default size if fetch fails
        } finally {
            setIsLoadingConfig(false);
        }
    };

    const formatFileSize = (bytes) => {
        if (bytes && !isNaN(bytes) && bytes > 0) {
            const sizes = ['B', 'KB', 'MB', 'GB'];
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
        }
        return '';
    };

    const getFileName = (url) => {
        if (!url) return '';
        if (url.includes('liaplus-dashboard-storage')) {
            const fileName = url.split('/').pop();
            return decodeURIComponent(fileName);
        }
        return decodeURIComponent(url);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file) {
            await handleFileUpload(file);
        }
    };

    const handleFileUpload = async (file) => {
        if (file.size > maxFileSize * 1024 * 1024) {
            toast.error(`File size exceeds the limit of ${maxFileSize}MB.`, {
                duration: 5000,
            });
            return;
        }
        setIsUploading(true);
        try {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const base64Data = event.target.result;
                const fileData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    content: base64Data
                };
                localStorage.setItem('KnowledgeBase', JSON.stringify(fileData));
                onFileUpload({
                    name: file.name,
                    size: file.size,
                    type: file.type
                });
                toast.success('File uploaded successfully');
            };
            reader.readAsDataURL(file);
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/v1/assistant/delete-knowledgebase/${assistantId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.status !== 200) {
                throw new Error('Failed to delete knowledge base');
            }
            onFileDelete();
            setDeleteModalOpen(false);
            setConsentChecked(false);
            toast.success(response?.data?.message);
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error('Error deleting file');
        }
    };

    const handleDownload = () => {
        try {
            const storedFileData = JSON.parse(localStorage.getItem('KnowledgeBase'));
            if (!storedFileData || !storedFileData.name) {
                throw new Error('No file found');
            }

            if (!storedFileData.name.startsWith('https')) {
                throw new Error('File URL not valid');
            }

            const fileUrl = storedFileData.name;
            const fileName = getFileName(fileUrl);

            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = fileUrl;
            a.download = fileName;

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);

            toast.success('File download started');
        } catch (error) {
            console.error('Error initiating download:', error);
            if (error.message === 'File URL not valid') {
                toast.error('Failed to download file. Please publish the assistant first then try again.', {
                    duration: 5000,
                });
            } else {
                toast.error('Failed to download file. Please try again later.', {
                    duration: 5000,
                });
            }
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            {!existingFile ? (
                <Box
                    sx={{
                        width: "100%",
                        height: { xs: "120px", sm: "150px" },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        border: isDragging ? "2px dashed #1d4ed8" : "2px dashed #D9D9D9",
                        borderRadius: "8px",
                        cursor: "pointer",
                        bgcolor: "#F9FAFB",
                        p: 2,
                        opacity: isLoadingConfig ? 0.7 : 1,
                        pointerEvents: isLoadingConfig ? 'none' : 'auto'
                    }}
                    onDragEnter={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(true);
                    }}
                    onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(true);
                    }}
                    onDragLeave={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDragging(false);
                    }}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) handleFileUpload(file);
                        }}
                        accept=".pdf,.doc,.docx,.txt,.csv,.xlsx"
                    />
                    <UploadFile sx={{ fontSize: { xs: "1.5rem", sm: "2rem" }, mb: 1 }} />
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                            <span style={{ textDecoration: "underline", color: "#02042D" }}>
                                Upload File
                            </span> or Drag here
                        </Typography>
                        <Typography 
                            variant="caption" 
                            sx={{ 
                                display: 'block', 
                                mt: 1, 
                                color: 'text.secondary',
                                fontSize: { xs: '0.7rem', sm: '0.75rem' }
                            }}
                        >
                            Supported formats: .pdf, .doc, .docx, .txt, .csv, .xlsx
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        border: "1px solid #E5E7EB",
                        borderRadius: "8px",
                        p: { xs: 1, sm: 2 },
                        bgcolor: "#fff"
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', sm: 'row' }, 
                        alignItems: { xs: 'flex-start', sm: 'center' }, 
                        justifyContent: 'space-between', 
                        gap: { xs: 1, sm: 2 }
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                            <Description sx={{ fontSize: { xs: 24, sm: 30, md: 40 }, color: '#02042D', mr: { xs: 1, sm: 2 } }} />
                            <Box>
                                <Typography sx={{ fontWeight: 450, color: '#111827', fontSize: { xs: '0.7rem', sm: '0.85rem', md: '1rem' } }}>
                                    {getFileName(existingFile.name)}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1, paddingTop: { xs: '4px', sm: '8px' } }}>
                                    <Typography variant="caption" sx={{ color: '#6B7280', fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.75rem' } }}>
                                        {existingFile.size ? formatFileSize(existingFile.size) : ''}
                                    </Typography>
                                    <Box
                                        component="label"
                                        sx={{ 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            color: '#2563EB',
                                            cursor: 'pointer',
                                            fontSize: { xs: "14px", sm: "16px", md: "20px" },
                                            transition: 'color 0.3s',
                                            '&:hover': { 
                                                color: '#1E40AF',
                                            }
                                        }}
                                    >
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const file = e.target.files?.[0];
                                                if (file) handleFileUpload(file);
                                            }}
                                            accept=".pdf,.doc,.docx,.txt,.csv,.xlsx"
                                        />
                                        <Sync sx={{ fontSize: { xs: 14, sm: 16, md: 18 }, mr: 0.5 }} />
                                        <Typography variant="caption" sx={{ fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.75rem' } }}>Replace</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ 
                            display: 'flex', 
                            gap: { xs: 1, sm: 2 }, 
                            flexWrap: 'wrap', 
                            justifyContent: { xs: 'flex-start', sm: 'flex-end' }, 
                            width: { xs: '100%', sm: 'auto' },
                            mt: { xs: 1, sm: 0 }
                        }}>
                            <Button
                                variant="outlined"
                                startIcon={<FileDownloadOutlinedIcon />}
                                onClick={handleDownload}
                                sx={{ 
                                    color: '#2563EB',
                                    borderColor: '#2563EB',
                                    textTransform: 'none',
                                    '&:hover': { 
                                        backgroundColor: 'rgba(37, 99, 235, 0.08)',
                                        borderColor: '#2563EB',
                                    },
                                    flexGrow: { xs: 1, sm: 0 },
                                    fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.875rem' },
                                    padding: { xs: '4px 8px', sm: '6px 16px' },
                                }}
                            >
                                Download
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<ClearOutlinedIcon />}
                                onClick={() => setDeleteModalOpen(true)}
                                sx={{ 
                                    color: '#DC2626',
                                    borderColor: '#DC2626',
                                    textTransform: 'none',
                                    '&:hover': { 
                                        backgroundColor: 'rgba(220, 38, 38, 0.08)',
                                        borderColor: '#DC2626',
                                    },
                                    flexGrow: { xs: 1, sm: 0 },
                                    fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.875rem' },
                                    padding: { xs: '4px 8px', sm: '6px 16px' },
                                }}
                            >
                                Remove
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
            
            {isUploading && (
                <Box 
                    sx={{ 
                        width: '100%',
                        height: '2px',
                        bgcolor: 'rgba(0, 0, 0, 0.1)',
                        mt: 1,
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            width: '30%',
                            height: '100%',
                            bgcolor: '#3662E3',
                            animation: 'loading 1s infinite linear',
                            '@keyframes loading': {
                                '0%': { transform: 'translateX(-100%)' },
                                '100%': { transform: 'translateX(400%)' }
                            }
                        }}
                    />
                </Box>
            )}

            <Modal 
                open={deleteModalOpen} 
                onClose={() => {
                    setDeleteModalOpen(false);
                    setConsentChecked(false);
                }}
            >
                <Box sx={{ 
                    width: "100%", 
                    height: "100%", 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    backdropFilter: "blur(10px)"
                }}>
                    <Box sx={{ 
                        width: { xs: "90%", sm: "380px" }, 
                        bgcolor: "#FFF", 
                        padding: { xs: "20px", sm: "10px 47px 47px 47px" }, 
                        borderRadius: "18.8px", 
                        display: "flex", 
                        flexDirection: "column", 
                        gap: "15.67px" 
                    }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Close 
                                onClick={() => {
                                    setDeleteModalOpen(false);
                                    setConsentChecked(false);
                                }}
                                sx={{ cursor: 'pointer' }}
                            />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: "18.8px", alignItems: "center" }}>
                            <HazardIcon sx={{ width: { xs: "40px", sm: "60px" }, height: { xs: "40px", sm: "60px" }, color: '#DC2626' }} />

                            <Typography variant="body1" textAlign="center" fontWeight="500" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                                Are you sure you want to delete this knowledge base file?
                            </Typography>

                            <Box display="flex" alignItems="flex-start" bgcolor="#F7F9FD" p={1} borderRadius={1}>
                                <Checkbox
                                    id="consent-checkbox"
                                    checked={consentChecked}
                                    onChange={(e) => setConsentChecked(e.target.checked)}
                                    sx={{ p: { xs: 0.5, sm: 1 } }}
                                />
                                <Typography component="label" htmlFor="consent-checkbox" sx={{ fontSize: { xs: "11px", sm: "12.537px" }, ml: 1 }}>
                                    This will permanently delete the knowledge base file and all its contents.
                                </Typography>
                            </Box>

                            <Box sx={{ 
                                width: "100%", 
                                display: "flex", 
                                gap: "1rem", 
                                justifyContent: 'center',
                                flexDirection: { xs: 'column', sm: 'row' }
                            }}>
                                <Button 
                                    variant="contained" 
                                    onClick={handleDelete}
                                    disabled={!consentChecked}
                                    sx={{ 
                                        textWrap: "nowrap", 
                                        fontSize: { xs: "11px", sm: "12.537px" }, 
                                        bgcolor: "#02042D", 
                                        textTransform: "none", 
                                        padding: { xs: "6px 12px", sm: "8px 16px" },
                                        width: { xs: '100%', sm: 'auto' }
                                    }}
                                >
                                    Yes, delete this file
                                </Button>

                                <Button 
                                    onClick={() => {
                                        setDeleteModalOpen(false);
                                        setConsentChecked(false);
                                    }} 
                                    variant="outlined" 
                                    sx={{ 
                                        fontSize: { xs: "11px", sm: "12.537px" }, 
                                        color: "#02042D", 
                                        borderColor: "gray",
                                        padding: { xs: "6px 12px", sm: "8px 16px" },
                                        width: { xs: '100%', sm: 'auto' }
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default KnowledgeBaseUpload;
