
import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal, Button, Input, TextareaAutosize, IconButton, Tooltip } from '@mui/material';
import { SearchOutlined as SearchOutlinedIcon, FileDownloadOutlined as FileDownloadOutlinedIcon, Cancel as CancelIcon, Description, Sync, Delete } from '@mui/icons-material';
import { formatFileSize } from '../utils/fileUtils';

const ScheduleModal = ({ 
    scheduleModalOpen, 
    setScheduleModalOpen, 
    isDragging, 
    setIsDragging, 
    scheduleFile, 
    setScheduleFile, 
    handleDrop, 
    handleScheduleFileUpload: externalHandleScheduleFileUpload, 
    downloadSampleFile, 
    campaignName, 
    setCampaignName, 
    campaignDescription, 
    setCampaignDescription, 
    scheduleDate, 
    setScheduleDate, 
    scheduleTime, 
    setScheduleTime, 
    fileInputRef, 
    setPreviewModalOpen, 
    loadPreviewData, 
    setTotalRows, 
    totalRows,
    isEditMode
}) => {
    const [validationErrors, setValidationErrors] = useState({});
    const [touched, setTouched] = useState({});

    const clearForm = () => {
        setCampaignName('');
        setCampaignDescription('');
        setScheduleFile(null);
        setScheduleDate('');
        setScheduleTime('');
        setValidationErrors({});
        setTouched({});
    };

    useEffect(() => {
        if (scheduleModalOpen && !isEditMode) {
            clearForm();
        }
    }, [scheduleModalOpen, isEditMode]);

    const inputStyle = {
        width: '100%',
        padding: '8px',
        border: '1px solid #D9D9D9',
        borderRadius: '4px',
        height: '36px',
        '&:focus': {
            outline: 'none',
            border: '1px solid #D9D9D9',
        },
    };

    const validateField = (name, value) => {
        switch (name) {
            case 'campaignName':
                if (!value) return 'Campaign name is required';
                if (value.length < 3) return 'Minimum 3 characters required';
                if (value.length > 50) return 'Maximum 50 characters allowed';
                return '';
            case 'campaignDescription':
                if (!value) return 'Campaign description is required';
                if (value.length < 3) return 'Minimum 3 characters required';
                if (value && value.length > 200) return 'Maximum 200 characters allowed';
                return '';
            case 'scheduleDate':
                if (!value) return 'Schedule date is required';
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                const selectedDate = new Date(value);
                if (selectedDate < today) return 'Please select a future date';
                return '';
            case 'scheduleTime':
                if (!value) return 'Schedule time is required';
                if (scheduleDate) {
                    const now = new Date();
                    const selectedDateTime = new Date(`${scheduleDate} ${value}`);
                    if (selectedDateTime < now) return 'Please select a future time';
                }
                return '';
            case 'scheduleFile':
                if (!scheduleFile) return 'Please upload a file';
                return '';
            default:
                return '';
        }
    };

    const handleScheduleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            loadPreviewData(file);
            setTotalRows(file);
            setValidationErrors(prev => ({ ...prev, scheduleFile: '' }));
        }
        externalHandleScheduleFileUpload(event);
    };

    const handleInputChange = (field, value) => {
        setTouched(prev => ({ ...prev, [field]: true }));
        const error = validateField(field, value);
        setValidationErrors(prev => ({ ...prev, [field]: error }));
    };

    const handleScheduleSubmit = () => {
        const fields = ['campaignName', 'campaignDescription', 'scheduleDate', 'scheduleTime', 'scheduleFile'];
        const newErrors = {};
        let hasErrors = false;

        fields.forEach(field => {
            let value;
            switch (field) {
                case 'scheduleFile':
                    value = scheduleFile;
                    break;
                case 'campaignName':
                    value = campaignName;
                    break;
                case 'campaignDescription':
                    value = campaignDescription;
                    break;
                case 'scheduleDate':
                    value = scheduleDate;
                    break;
                case 'scheduleTime':
                    value = scheduleTime;
                    break;
                default:
                    value = '';
            }
            const error = validateField(field, value);
            if (error) {
                newErrors[field] = error;
                hasErrors = true;
            }
        });

        setValidationErrors(newErrors);
        setTouched(fields.reduce((acc, field) => ({ ...acc, [field]: true }), {}));

        if (!hasErrors) {
            setScheduleModalOpen(false);
            setPreviewModalOpen(true);
            loadPreviewData();
        }
    };

    const renderInput = (field, label, props) => {
        const error = touched[field] && validationErrors[field];
        return (
            <Box>
                <Typography variant="body2" sx={{ color: '#000', mb: 1 }}>
                    {label} {props.required && <span style={{ color: 'red' }}>*</span>}
                </Typography>
                <Tooltip title={error || ''} open={!!error} placement="top">
                    <Input
                        {...props}
                        onBlur={() => handleInputChange(field, props.value)}
                        onChange={(e) => {
                            props.onChange(e);
                            handleInputChange(field, e.target.value);
                        }}
                        disableUnderline
                        sx={{
                            ...inputStyle,
                            border: error ? '1px solid red' : '1px solid #D9D9D9',
                        }}
                    />
                </Tooltip>
            </Box>
        );
    };

    const FileDisplaySection = () => (
        <Box
            sx={{
                width: "100%",
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                p: 2,
                bgcolor: "#fff"
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <Description sx={{ fontSize: 27, mt: 0.5 }} />
                <Box sx={{ 
                    flex: 1,
                    minWidth: 0,
                }}>
                    <Typography 
                        sx={{ 
                            fontWeight: 500, 
                            color: '#111827',
                            wordBreak: 'break-word',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            lineHeight: '1.2em',
                            maxHeight: '2.4em'
                        }}
                    >
                        {scheduleFile.name}
                    </Typography>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2,
                        mt: 0.5
                    }}>
                        <Typography variant="caption" sx={{ color: '#6B7280' }}>
                            {formatFileSize(scheduleFile.size)}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <label style={{ cursor: 'pointer' }}>
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleScheduleFileUpload}
                                    accept=".xlsx,.xls,.csv"
                                />
                                <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 0.5,
                                    color: '#3662E3',
                                    '&:hover': { opacity: 0.8 }
                                }}>
                                    <Sync sx={{ fontSize: 16 }} />
                                    <Typography variant="caption">Update</Typography>
                                </Box>
                            </label>
                            <Box
                                onClick={() => setScheduleFile(null)}
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 0.5,
                                    color: '#DC2626',
                                    cursor: 'pointer',
                                    '&:hover': { opacity: 0.8 }
                                }}
                            >
                                <Delete sx={{ fontSize: 16 }} />
                                <Typography variant="caption">Remove</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Modal 
            open={scheduleModalOpen} 
            onClose={() => {
                setScheduleModalOpen(false);
                if (!isEditMode) {
                    clearForm();
                }
            }}
        >
            <Box sx={{ width: "100dvw", display: "grid", placeContent: "center", height: "100vh", backdropFilter: "blur(10px)" }}>
                <Box sx={{ width: "500px", bgcolor: "#FFF", padding: "20px", borderRadius: "18.8px", display: "flex", flexDirection: "column", gap: "15.67px" }}>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>Bulk Call Scheduling</Typography>
                        <IconButton onClick={() => {
                            setScheduleModalOpen(false);
                            if (!isEditMode) {
                                clearForm();
                            }
                        }} sx={{ color: '#333' }}>
                            <CancelIcon />
                        </IconButton>
                    </Box>

                    {renderInput('campaignName', 'Campaign Name', {
                        placeholder: "Enter campaign name (3-50 chars)",
                        value: campaignName,
                        onChange: (e) => setCampaignName(e.target.value),
                        required: true,
                    })}

                    <Box>
                        <Typography variant="body2" sx={{ color: '#000', mb: 1 }}>
                            Campaign Description <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <TextareaAutosize
                            placeholder="Describe your campaign."
                            value={campaignDescription}
                            onChange={(e) => {
                                setCampaignDescription(e.target.value);
                                handleInputChange('campaignDescription', e.target.value);
                            }}
                            onBlur={() => handleInputChange('campaignDescription', campaignDescription)}
                            minRows={2}
                            maxRows={3}
                            style={{
                                ...inputStyle,
                                height: 'auto',
                                border: (touched.campaignDescription && validationErrors.campaignDescription) ? '1px solid red' : '1px solid #D9D9D9',
                            }}
                        />
                        {touched.campaignDescription && validationErrors.campaignDescription && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                                {validationErrors.campaignDescription}
                            </Typography>
                        )}
                    </Box>

                    <Box>
                        <Typography variant="body2" sx={{ color: '#000', mb: 1 }}>
                            Campaign Recipients <span style={{ color: 'red' }}>*</span>
                        </Typography>
                        {!scheduleFile ? (
                            <Box 
                                sx={{
                                    width: "100%",
                                    height: "140px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: (touched.scheduleFile && validationErrors.scheduleFile) ? "1px solid red" : "1px solid #E5E7EB",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    backgroundColor: isDragging ? "#f8f9fa" : "",
                                }}
                                onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
                                onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
                                onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false); }}
                                onDrop={handleDrop}
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={handleScheduleFileUpload}
                                    accept=".xlsx,.xls"
                                />
                                
                                <Typography sx={{ color: '#666', textAlign: 'center', mb: 1 }}>
                                    Import File
                                    <br />
                                    <span style={{ fontSize: '0.8rem' }}>Max 10MB | Supports only .xls, .xlsx</span>
                                </Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                                    <Button 
                                        variant="outlined"
                                        size="small"
                                        sx={{ 
                                            color: '#7A7A7A',
                                            borderColor: '#E5E7EB',
                                            '&:hover': { borderColor: '#E5E7EB', bgcolor: 'rgba(74, 74, 255, 0.1)' },
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => fileInputRef.current?.click()}
                                        startIcon={<SearchOutlinedIcon/>}
                                    >
                                        Browse Files
                                    </Button>
                                    <Button 
                                        variant="outlined"
                                        size="small"
                                        sx={{ 
                                            color: '#7A7A7A',
                                            borderColor: '#E5E7EB',
                                            '&:hover': { borderColor: '#E5E7EB', bgcolor: 'rgba(74, 74, 255, 0.1)' },
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={downloadSampleFile}
                                        startIcon={<FileDownloadOutlinedIcon/>}
                                    >
                                                                                Sample File
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <FileDisplaySection />
                        )}
                        {touched.scheduleFile && validationErrors.scheduleFile && (
                            <Typography variant="caption" sx={{ color: 'red', mt: 1 }}>
                                {validationErrors.scheduleFile}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '195px' }}>
                        {renderInput('scheduleDate', 'Schedule On', {
                            type: "date",
                            value: scheduleDate,
                            onChange: (e) => setScheduleDate(e.target.value),
                            placeholder: "DD/MM/YY",
                            required: true,
                            min: new Date().toISOString().split('T')[0],
                        })}

                        {renderInput('scheduleTime', 'Select Time', {
                            type: "time",
                            value: scheduleTime,
                            onChange: (e) => setScheduleTime(e.target.value),
                            placeholder: "HH:MM AM/PM",
                            required: true,
                        })}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                        <Button 
                            variant="outlined" 
                            onClick={() => {
                                setScheduleModalOpen(false);
                                if (!isEditMode) {
                                    clearForm();
                                }
                            }}
                            sx={{ 
                                color: '#02042c',
                                borderColor: '#02042c',
                                '&:hover': { borderColor: '#3333FF', bgcolor: 'rgba(74, 74, 255, 0.1)' },
                                textTransform: 'capitalize',
                            }}
                        >
                            Back
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={handleScheduleSubmit}
                            sx={{ 
                                bgcolor: "#02042D", 
                                color: "white",
                                '&:hover': { bgcolor: "#0a0b3b" },
                                textTransform: 'capitalize',
                            }}
                        >
                            Schedule
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ScheduleModal;