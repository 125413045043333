// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/login/rightOverlay.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginContainer {
  display: flex;
}
.leftBox {
  flex: 1 1;
  min-height: 100vh;
  position: relative;
  padding: 2rem;
}
.leftBox .logo {
  height: 2.3rem;
  width: auto;
}
.leftOverlay {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}
.loginAccountText {
    background: linear-gradient(90deg, rgb(135, 73, 126), blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 1.5rem !important;
    font-weight: bold !important;
    text-align: center;
}
.formbox{
    max-width: 70%;
    margin: auto;
}
  
.rightBox {
  flex: 1 1;
  min-height: 100vh;
  position: relative;
  background-color: #02042d;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightCard {
  background-color: #806099 !important;
  width: fit-content;
  padding: 2rem;
  max-width: 60%;
  box-shadow: 0 4px 8px rgba(128, 0, 128, 0.5);
}

.cardText {
  color: #ffffff;
}

.loginInput{
    margin-top:0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,SAAO;EACP,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,WAAW;AACb;AACA;IACI,2DAA2D;IAC3D,6BAA6B;IAC7B,oCAAoC;IACpC,qBAAqB;IACrB,4BAA4B;IAC5B,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;EACE,SAAO;EACP,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,yDAAmE;EACnE,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,4CAA4C;AAC9C;;AAEA;EACE,cAAc;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".loginContainer {\n  display: flex;\n}\n.leftBox {\n  flex: 1;\n  min-height: 100vh;\n  position: relative;\n  padding: 2rem;\n}\n.leftBox .logo {\n  height: 2.3rem;\n  width: auto;\n}\n.leftOverlay {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n  z-index: -1;\n}\n.loginAccountText {\n    background: linear-gradient(90deg, rgb(135, 73, 126), blue);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-clip: text;\n    font-size: 1.5rem !important;\n    font-weight: bold !important;\n    text-align: center;\n}\n.formbox{\n    max-width: 70%;\n    margin: auto;\n}\n  \n.rightBox {\n  flex: 1;\n  min-height: 100vh;\n  position: relative;\n  background-color: #02042d;\n  background-image: url(\"../../public/images/login/rightOverlay.png\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.rightCard {\n  background-color: #806099 !important;\n  width: fit-content;\n  padding: 2rem;\n  max-width: 60%;\n  box-shadow: 0 4px 8px rgba(128, 0, 128, 0.5);\n}\n\n.cardText {\n  color: #ffffff;\n}\n\n.loginInput{\n    margin-top:0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
