
import React from 'react';
import { Box, Button, Typography, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderBottom: '1px solid #E0E0E0',
    whiteSpace: 'nowrap',
    padding: '12px 16px',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PreviewModal = ({
    previewModalOpen,
    setPreviewModalOpen,
    previewData,
    totalRowCount,
    setConfirmationModalOpen,
    onBack, // New prop for handling back navigation
}) => {
    const handleNext = () => {
        setPreviewModalOpen(false);
        setConfirmationModalOpen(true);
    };

    return (
        <Modal 
            open={previewModalOpen} 
            onClose={() => setPreviewModalOpen(false)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{ 
                width: "480px",
                bgcolor: "#FFFFFF",
                borderRadius: "16px",
                p: 3,
                outline: 'none',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '90vh', // Ensure it doesn't exceed viewport height
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Preview File
                    </Typography>
                    <Close 
                        onClick={() => setPreviewModalOpen(false)}
                        sx={{ cursor: 'pointer' }}
                    />
                </Box>

                <Box sx={{ 
                    bgcolor: "#F8F9FA",
                    borderRadius: "8px",
                    p: 1.5,
                    mb: 2,
                    border: "1px solid #e6e7eb" 
                }}>
                    <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                        You imported {totalRowCount} rows, if preview doesn't look right, Please try uploading another file
                    </Typography>
                </Box>

                {previewData && previewData.length > 0 && (
                    <TableContainer 
                        component={Paper} 
                        sx={{ 
                            flexGrow: 1,
                            mb: 2,
                            maxHeight: 'calc(90vh - 250px)', // Adjust based on your needs
                            overflow: 'auto',
                        }}
                    >
                        <Table stickyHeader aria-label="preview table" size="small">
                            <TableHead>
                                <TableRow>
                                    {previewData[0].map((header, index) => (
                                        <StyledTableCell key={index}>{header}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {previewData.slice(1).map((row, rowIndex) => (
                                    <StyledTableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell 
                                                key={cellIndex} 
                                                sx={{ 
                                                    whiteSpace: 'nowrap', 
                                                    overflow: 'hidden', 
                                                    textOverflow: 'ellipsis', 
                                                    maxWidth: '150px'
                                                }}
                                            >
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 'auto' }}>
                    <Button 
                        variant="outlined" 
                        onClick={onBack} // Use the onBack prop here
                        sx={{ 
                            color: '#495057',
                            borderColor: '#CED4DA',
                            '&:hover': {
                                borderColor: '#ADB5BD',
                                bgcolor: 'rgba(206, 212, 218, 0.1)',
                            },
                            fontSize: '0.875rem',
                        }}
                    >
                        Back
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleNext}
                        sx={{ 
                            bgcolor: "#02042D", 
                            color: "white",
                            '&:hover': { bgcolor: "#0a0b3b" },
                            fontSize: '0.875rem',
                        }}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PreviewModal;



