// Helper function to format the date
const formatDate = (date) => {
  const day = date.getDate(); // Day of the month
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month (0-based, so add 1) with leading zero
  const year = date.getFullYear(); // Year
  const hours = date.getHours(); // Hours
  const minutes = date.getMinutes().toString().padStart(2, "0"); // Minutes with leading zero
  const amPm = hours >= 12 ? "PM" : "AM"; // AM/PM

  const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

  return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${amPm}`;
};

module.exports = {
  formatDate,
};
