import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import { Box, FormControl, Grid, InputLabel, Menu, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { FilterList, AnalyticsOutlined, MoreVert } from '@mui/icons-material';
import { Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, AreaChart, Area } from "recharts";
import api from "../../api";
import { ChartContainer } from '../../components/Chart';
import { formatDate } from '../../components/formate';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { downloadAssistantPerformanceCSV, downloadAvgDurationByIssueTypeCSV, downloadCallAnalysisReport, downloadTotalSpentCSV } from '../../components/download';
import { CustomRadarChart } from '../../components/CustomRadarChart';
import { Label } from 'recharts';

const CustomTick = ({ x, y, payload, width }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <foreignObject x={-width / 2} y={0} width={width} height={100}>
                <div style={{
                    width: `${width - 10}px`,
                    whiteSpace: 'break-spaces',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',  // Ensures word wrapping
                    textAlign: 'center',
                    fontSize: 10
                }}>
                    {payload.value}
                </div>
            </foreignObject>
        </g>
    );
};  

const Overview = () => {
    const [assistantList, setAssistantList] = useState([])
    const [assistant, setAssistant] = useState("All")
    const [filter, setFilter] = useState("weekly");
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState({});
    const { company_id } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [dashboardData, setDashboardData] = useState({});
    const [phones, setPhones] = useState([]);

    const token = JSON.parse(localStorage.getItem("token"))?.token;

    const getAllAssistants = useCallback(async () => {
        try {

            const response = await api.post(`/organizations/assistants-by-company`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
            setAssistantList(response.data.data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    }, [token]);

    const getOverview = useCallback(async () => {
        setLoading(true);
        try {
            const params = {
                filter: filter
            };
            if (assistant !== 'All') {
                params.assistantId = assistant;
            }

            const response = await api.post(`/organizations/overview`,
                {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                    params
                }
            );
            setDashboardData(response.data.data);
            setTotalRows(response.data.totalRows || 0);
            setTotalPages(response.data.totalPages || 0);

            setLoading(false);
        } catch (error) {
            console.error("Error fetching overview:", error);
            setLoading(false);
        }
    }, [assistant, filter, token]);

    const getOrganization = useCallback(async () => {
        try {
            const response = await api.get(`/organizations/${company_id}`, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });
            setOrganization(response.data);
        } catch (error) {
            console.log(error);
        }
    }, [company_id, token]);

    const getAllPhoneNumbers = useCallback(async () => {
        try {
            const response = await api.post(`/organizations/phones-by-company?limit=999`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            setPhones(response.data)
        } catch (error) {
            console.log(error);
        }
    }, [token])

    const getAllDemoAssistants = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.post(`/assistants/demo-assistants?limit=999`, {},
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );

            setAssistantList(response.data.data?.demoAssistants);

            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            getOrganization();
            getOverview();
            getAllPhoneNumbers();
        }
    }, [token, getOrganization, getOverview, getAllPhoneNumbers]);

    useEffect(() => {
        if (organization?.ShowDemo === true) {
            getAllDemoAssistants();
        } else if (organization?.ShowDemo === false) {
            getAllAssistants();
        }
    }, [token, getOrganization, getAllAssistants, getAllDemoAssistants, organization?.ShowDemo]);

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Navbar title={`Overview | ${organization?.Name}`} />

            <Box sx={{ padding: "1.5rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1.8rem" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h4">Dashboard Summary</Typography>

                        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <FormControl size='small' sx={{ width: "12rem", bgcolor: "#FFF", height: 'fit-content' }}>
                                <InputLabel id="assistant">Assistants</InputLabel>
                                <Select
                                    labelId="Assistant"
                                    id="assistant"
                                    value={assistant}
                                    label="Assistant"
                                    onChange={(e) => setAssistant(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    {assistantList.map((assistant) => (
                                        <MenuItem value={assistant._id} key={assistant._id}>{assistant.Name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                            <FormControl size='small' sx={{ width: "8rem", bgcolor: "#FFF", height: 'fit-content' }}>
                                <InputLabel id="filter"><FilterList /></InputLabel>
                                <Select
                                    labelId="filter"
                                    id="filter"
                                    value={filter}
                                    label="Filter"
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="weekly">Weekly</MenuItem>
                                    <MenuItem value="monthly">Monthly</MenuItem>
                                    <MenuItem value="yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                    <Grid container spacing={2}>

                        <Grid item xs={12} md={12} lg={6}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <AnalyticsOutlined sx={{ fontSize: "2rem" }} />
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box>
                                        <Typography variant="h6">Assistant Performance</Typography>
                                        <Typography sx={{ fontSize: ".8rem" }} color="#6A6A6A">
                                            Overview of each assistant's call count, duration, and cost.
                                        </Typography>
                                    </Box>
                                    <MoreVert sx={{ cursor: "pointer" }} onClick={(e) => setAnchorEl(e.currentTarget)} />

                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}>
                                        <MenuItem onClick={() => {
                                            toast.promise(downloadAssistantPerformanceCSV({ filter, assistant, organization: company_id }), {
                                                loading: "Downloading CSV File",
                                                success: "CSV File Downloaded Successfully",
                                                error: "Failed to Download CSV File"
                                            })
                                            setAnchorEl(null)
                                        }}
                                        >
                                            Download CSV
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>

                            <TableContainer sx={{ bgcolor: "rgba(177, 177, 190, 0.11)", borderRadius: "1rem", height: "auto", marginTop: ".5rem" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Assistant</TableCell>
                                            <TableCell>Total Calls</TableCell>
                                            <TableCell>Avg. Duration</TableCell>
                                            <TableCell>Total Cost ($)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dashboardData.assistantPerformance?.length > 0 ? dashboardData.assistantPerformance?.map((performance) => (
                                            <TableRow key={performance.assistantName}>
                                                <TableCell>{performance.assistantName}</TableCell>
                                                <TableCell>{performance.totalCalls}</TableCell>
                                                <TableCell>{performance.averageDuration}</TableCell>
                                                <TableCell>{performance.totalCost}</TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow>
                                                <TableCell colspan={4}>Assistant performance metrics will appear here once the call is made.
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={totalRows}
                                    page={page}
                                    onPageChange={(e, newpage) => { setPage(newpage) }}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0) }}
                                />
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                <AnalyticsOutlined sx={{ fontSize: "2rem" }} />
                                <Box>
                                    <Typography variant="h6">Phone Numbers</Typography>
                                    <Typography variant="body2">
                                        See the performance of your assistant and the metrics.
                                    </Typography>
                                </Box>
                            </Box>

                            <TableContainer sx={{ bgcolor: "rgba(177, 177, 190, 0.11)", borderRadius: "1rem", height: "auto", marginTop: ".5rem" }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Total Calls</TableCell>
                                            <TableCell>Avg. Duration</TableCell>
                                            <TableCell>Total Cost</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {phones.length > 0 && dashboardData.phoneNumberPerformance?.result?.length > 0 ? dashboardData.phoneNumberPerformance?.result?.map((phone) => (
                                            <TableRow key={phone.PhoneNumber}>
                                                <TableCell>{phone.PhoneNumber}</TableCell>
                                                <TableCell>{phone.totalCalls}</TableCell>
                                                <TableCell>{phone.averageDuration}</TableCell>
                                                <TableCell>{phone.totalCost}</TableCell>
                                            </TableRow>
                                        )) :
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    There are no phone numbers assigned at the moment.
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={totalRows}
                                    page={page}
                                    onPageChange={(e, newpage) => setPage(newpage)}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(1) }}
                                />
                            </TableContainer>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={6}>
                            <ChartContainer
                                title="Call Analysis"
                                subtitle="Insights on call performance on various factors."
                                download={() => downloadCallAnalysisReport({ filter, organization: company_id })}
                                chart={
                                    dashboardData.callAnalysisMetrics?.transferedResult || dashboardData.callAnalysisMetrics?.goalAchievementResult || dashboardData.callAnalysisMetrics?.userSatisfactionResult || dashboardData.callAnalysisMetrics?.scriptAdherenceResult || dashboardData.callAnalysisMetrics?.callAbandonmentResult ?
                                        <CustomRadarChart data={[
                                            { name: "Call Transferred", value: dashboardData.callAnalysisMetrics?.transferedResult || 0 },
                                            { name: "Goal Achievement", value: dashboardData.callAnalysisMetrics?.goalAchievementResult || 0 },
                                            { name: "User Satisfaction", value: dashboardData.callAnalysisMetrics?.userSatisfactionResult || 0 },
                                            { name: "Script Adherence (%)", value: dashboardData.callAnalysisMetrics?.scriptAdherenceResult || 0 },
                                            { name: "Call Abandonment", value: (dashboardData.callAnalysisMetrics?.callAbandonmentResult || 0).toFixed(2) },
                                        ]}
                                        /> :
                                        "No data found for applied filters."
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <ChartContainer
                                title="Total Spent"
                                download={() => downloadTotalSpentCSV({ filter, assistant, organization: company_id })}
                                chart={
                                    dashboardData?.totalCost?.dataPoints.length ?
                                        <AreaChart data={dashboardData?.totalCost?.dataPoints} >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="date"
                                                tickFormatter={formatDate}
                                                interval="preserveStartEnd"
                                                minTickGap={50}
                                                angle={-45}
                                                textAnchor="end"
                                                height={80}
                                            />
                                            <YAxis
                                                tickFormatter={(value) => (value)}
                                            />
                                            <Tooltip
                                                labelFormatter={(label) => `Date Range: ${formatDate(label)}`}
                                                formatter={(value) => [`$ ${value}`, "Total Cost"]}
                                            />
                                            <Area type="monotone" dataKey="totalCost" stroke="#02042D" fill="#A5A8E6" fillOpacity={0.3} />

                                        </AreaChart>
                                        : "Your call cost metrics will appear here once the call is made."
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                        <ChartContainer
                    title={"Average Handle Time by Issue Type"}
                    subtitle="Average resolution time per issue type."
                    download={() => downloadAvgDurationByIssueTypeCSV({ filter, assistant, organization: company_id })}
                    chart={
                        dashboardData.avgCallMinutesByIssueType ?
                            <BarChart data={dashboardData?.avgCallMinutesByIssueType} width={500} height={300}>
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis
                                    dataKey="issueType"
                                    fontSize={10}
                                    tick={<CustomTick width={400 / dashboardData.avgCallMinutesByIssueType.length} />}
                                />
                                <YAxis
                                    fontSize={10}
                                    tick={<CustomTick width={dashboardData.avgCallMinutes/3600}/>}
                                >
                                    <Label value="Average Handle Time" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
                                </YAxis>
                                <Tooltip 
                                    formatter={(value, name) => {
                                        // Round seconds to nearest integer
                                        const roundedValue = Math.round(value);
                                        const hours = Math.floor(roundedValue / 3600);
                                        const minutes = Math.floor((roundedValue % 3600) / 60);
                                        const seconds = roundedValue % 60;

                                        // Return formatted time with labels: either hhhrs:mmmins or mmmins:ssseconds
                                        if (hours > 0) {
                                            return [`${String(hours).padStart(2, '0')}hrs:${String(minutes).padStart(2, '0')}mins`, "Average Duration"];
                                        } else {
                                            return [`${String(minutes).padStart(2, '0')}mins:${String(seconds).padStart(2, '0')}seconds`, "Average Duration"];
                                        }
                                    }} 
                                    cursor={{ fill: "transparent" }} 
                                />
                                <Bar dataKey="avgDuration" fill="#02042D" />
                            </BarChart>
                        : "No data found for applied filters."
                    }
                />
                        </Grid>
                    </Grid>
                </Box>
            </Box >
        </>
    )
}

export default Overview;