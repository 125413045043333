import React from 'react';
import { Button, FormLabel, Switch, TextField, Box, FormControl, CircularProgress } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

const ShareAssistantForm = ({
    isOpen,
    onClose,
    isPasswordRequired,
    setIsPasswordRequired,
    setPassword,
    password,
    credits,
    setCredits,
    date,
    setDate,
    time,
    setTime,
    handleGenerateLink,
    handleManageAccessClick,
    loading
  }) => {

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1,
          gap: "90px",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Button variant="text" onClick={onClose} sx={{ minWidth: "auto" }}>
            <ArrowLeft sx={{ width: 32, height: 32, color: "black" }} />
          </Button>
          <h6 sx={{ fontWeight: 600 }}>Share Assistant Access</h6>
        </Box>
        <Button
          variant="outlined"
          onClick={handleManageAccessClick}
          sx={{
            borderColor: "rgba(10, 11, 28, 0.9)",
            color: "rgba(10, 11, 28, 0.9)",
            fontSize: 12,
            fontWeight: 700,
          }}
        >
          Manage Shared Access
        </Button>
      </Box>

      <Box sx={{ paddingTop: "12px", gap: "12px" }}>
        {/* Additional Security Section */}
        <Box sx={{ marginBottom: 2 }}>
          <FormLabel sx={{ fontSize: 16 }}>Additional Security</FormLabel>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <Switch
              checked={isPasswordRequired}
              onChange={(e) => setIsPasswordRequired(e.target.checked)}
            />
            <span sx={{ fontSize: 16 }}>Password Required</span>
          </Box>
        </Box>

        {/* Password Field */}
        {isPasswordRequired && (
          <Box sx={{ marginBottom: 2 }}>
            <FormLabel htmlFor="password" sx={{ fontSize: 16 }}>
              Password
            </FormLabel>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel sx={{ fontSize: 16, mb: 1 }}>
                Expiration Date
              </FormLabel>
              <DatePicker
                value={date}
                onChange={(newValue) => setDate(newValue)}
                format="dd/MM/yyyy"
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel sx={{ fontSize: 16, mb: 1 }}>
                Expiration Time
              </FormLabel>
              <TimePicker
                value={time}
                onChange={(newValue) => setTime(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </FormControl>
          </Box>
        </LocalizationProvider>

        <Box sx={{ marginTop: 2 }}>
          <FormLabel sx={{ fontSize: 16 }}>Credits allotted</FormLabel>
          <TextField
            type="number"
            value={credits}
            onChange={(e) => setCredits(e.target.value)}
            placeholder="Max 100"
            fullWidth
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: 2,
            paddingTop: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={{ borderColor: "#A9A9A9", color: "black", padding: "8px 24px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: loading ? "#A9A9A9" : "#0A0B1C", // Optional: Change color to indicate disabled state
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: 200, // Ensures the button maintains a consistent size
              padding: "8px 24px", // Keeps padding consistent
            }}
            onClick={handleGenerateLink}
            disabled={loading}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress size={20} sx={{ color: "white" }} />
              </Box>
            ) : (
              "Generate and Copy Link"
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ShareAssistantForm;
