import React from 'react';
import {
    Box,
    Typography,
    Button,
    Paper,
    Stack,
    styled
} from '@mui/material';
import { Check } from '@mui/icons-material';

const PlanPaper = styled(Paper)(({ theme, isactive }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: isactive === 'true' ? theme.palette.primary.main : '#E4E7EC',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
}));

const ActivePlanBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    padding: theme.spacing(0.5, 1),
    backgroundColor: '#F4F1FE',
    borderRadius: theme.shape.borderRadius,
    color: '#0D0B45',
    fontSize: '0.75rem',
}));

const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1.5),
    '& svg': {
        color: '#0D0B45',
        width: 20,
        height: 20,
        marginTop: 2,
    }
}));

const PlanWrapper = styled(Box)(({ theme }) => ({
    flex: '1 1 0',
    minWidth: 280,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 'calc(25% - ${theme.spacing(3)})',
    }
}));

const PlanCard = ({ 
  plan,
  plans,
    isActive, 
    onPlanSelect 
}) => {

    const isCurrentPlan = isActive === plan.name;
    const planPrice = plan.price.split("/")
    const getButtonText = () => {
      if (plan.name === "Enterprise") {
        return "Contact Sales";
      }
      if (isCurrentPlan) {
        return "Current Plan"; // Current plan is always labeled as such.
      }

      const currentPlanIndex = plans.findIndex((p) => p.name === isActive); // Find index of current plan
      const planIndex = plans.findIndex((p) => p.name === plan.name); // Find index of the plan being rendered
      
      if (planIndex < currentPlanIndex) {
        return "Contact Sales"; // Previous plan (downgrade)
      }
      if (planIndex > currentPlanIndex) {
        return "Upgrade Plan"; // Higher plan (upgrade)
      }
      return plan.hasTrial ? "Start Free Trial" : "Select Plan";
    };

  const handlePlanClick = () => {
        onPlanSelect(plan.name);
    };

    return (
      <PlanWrapper>
        <PlanPaper elevation={0} isactive={(isActive === plan.name).toString()}>
          {isActive && plan.name === isActive && (
            <ActivePlanBadge>Your Plan</ActivePlanBadge>
          )}

          <Stack spacing={3}>
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "#1C1E2E",
                  mb: 0.5,
                }}
              >
                {plan.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                {plan.description}
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "#1C1E2E",
                  }}
                >
                  {planPrice[0]}
                </Typography>
                {planPrice.length > 1  && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 0.5 }}
                  >
                    / {planPrice[1]}
                  </Typography>
                )}
              </Box>
            </Box>

            <Stack spacing={2} sx={{ mb: 4 }}>
              {plan.features
                .filter((feature) => !feature.includes("Price Currency $")) // Filter out features with '$'
                .map((feature, index) => (
                  <FeatureItem key={index}>
                    <Check />
                    <Typography variant="body2" color="text.secondary">
                      {feature}
                    </Typography>
                  </FeatureItem>
                ))}
            </Stack>

            <Button
              fullWidth
              variant="contained"
              onClick={handlePlanClick}
              disabled={isCurrentPlan}
              sx={{
                bgcolor: isCurrentPlan ? "#E4E7EC" : "#0D0B45", // Change color for disabled state
                "&:hover": {
                  bgcolor: isCurrentPlan ? "#E4E7EC" : "#1A1860",
                },
                "&.Mui-disabled": {
                  bgcolor: "#E4E7EC",
                  color: "#6C7275", // Change text color for disabled state
                },
                textTransform: "none",
                py: 1.5,
              }}
            >
              {getButtonText()}
            </Button>
          </Stack>
        </PlanPaper>
      </PlanWrapper>
    );
};

export default PlanCard;