import { Avatar, Box, Button, Card, Typography } from '@mui/material';
import api from "../api";
import React, { useState } from 'react';
import { toast } from 'sonner';
import OtpBox from '../components/OtpBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sessionStart } from '../util';

const VerifyEmail = () => {
    const [otp, setOtp] = useState('');
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const isSession = searchParams.get("isSession");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post(`/users/validate-otp`, {
                otp,
                Email: email
            });

            const { token, UserId, Role, Organization, user } = response.data.data;

            toast.success(response.data.message);

            if (response.data.data) {
                localStorage.setItem("token", JSON.stringify({ token }));
                localStorage.setItem("UserId", UserId);
                localStorage.setItem("Role", Role);
                localStorage.setItem("Organization", Organization || "");
                localStorage.setItem("User", JSON.stringify(user));
                localStorage.setItem("sidebarOpen", true);
            }

            if (isSession !== "true") {
                sessionStart();
            }

	    if (user.Role === "SuperAdmin") {
                navigate("/overview");
            } else if (!Organization) {
                navigate("/create-company");
            } else {
                navigate(`/company/${Organization}/overview`);
            }

//            if (user.Role === "Admin") {
//                navigate(`/company/${Organization}/overview`);
//            } else if (user.Role === "User") {
//                navigate("/create-company");
//            } else if (user.Role === "SuperAdmin") {
//                navigate("/overview");
//            }

            window.location.reload();
            setLoading(false);
        } catch (error) {
            toast.error(error.response?.data?.message || "Error validating OTP");
            console.error("Error submitting OTP:", error);
            setLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            const response = await api.post(`/users/resend-otp`, {
                Email: email
            });
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response?.data?.message || "Error resending OTP");
            console.error("Error resending OTP:", error);
        }
    };

    return (
        <Box className="loginContainer">
            <Box className="leftBox">
                <img src="logo.png" className="logo" alt="Logo" />
                <img src="/images/login/leftOverlay.png" className="leftOverlay" alt="Overlay" />
                <Box mt={4} className="formbox" sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Typography className="loginAccountText">Please verify email account</Typography>
                    <Typography fontSize={14} textAlign="center">
                        Please enter the OTP sent to <b>{email}</b>
                    </Typography>
                    <form onSubmit={handleOtpSubmit} style={{ maxWidth: "400px", display: "flex", flexDirection: "column", gap: "10px", alignItems: "center", marginTop: "1rem" }}>
                        <OtpBox length={6} handleSubmit={setOtp} />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ bgcolor: "#02042D", mt: 1, color: "#FFF", "&:hover": { bgcolor: "#02042D", color: "#FFF", cursor: loading ? "not-allowed" : "pointer" } }}
                            fullWidth
                            disabled={loading || otp.length !== 6}
                        >
                            {loading ? "Verifying..." : "Verify"}
                        </Button>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button onClick={() => navigate(-1)}>Go Back</Button> {/* Go back to the previous page */}
                            <Button onClick={handleResendOtp}>Resend OTP</Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            <Box className="rightBox">
                <Card className="rightCard">
                    <Avatar alt="Vandana" src="/images/login/vandana.png" sx={{ width: 50, height: 50 }} />
                    <Typography className="cardText" mt={2}>
                        “LiaPlus AI has made my work much easier and more efficient. It's like having a personalized writing coach that saves time and boosts confidence.”
                    </Typography>
                    <Typography className="cardText" mt={2}>
                        Vandana Khurana
                    </Typography>
                    <Typography className="cardText">
                        BCO, India
                    </Typography>
                </Card>
            </Box>
        </Box>
    );
};

export default VerifyEmail;
