import React, { useRef, useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { UploadFile, SearchOutlined as SearchOutlinedIcon, FileDownloadOutlined as FileDownloadOutlinedIcon } from '@mui/icons-material';

const FileUpload = ({ onFileUpload, downloadSampleFile, validateFile }) => {
    const fileInputRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file && validateFile(file)) {
            await onFileUpload(file);
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "180px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: isDragging ? "2px dashed #02042D" : "2px dashed #D9D9D9",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: isDragging ? "#f8f9fa" : "",
            }}
            onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
            onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true); }}
            onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false); }}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
        >
            <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file && validateFile(file)) onFileUpload(file);
                }}
                accept=".xlsx,.xls,.csv"
            />
            <IconButton sx={{ color: '#02042c', mb: 1 }}>
                <UploadFile fontSize="large" />
            </IconButton>
            <Typography sx={{ color: '#666', textAlign: 'center', mb: 2 }}>
                Drag & Drop File Here
                <br />
                <span style={{ fontSize: '0.8rem' }}>Max 50MB | Supports only .xls, .xlsx, .csv</span>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{
                        color: '#02042c',
                        borderColor: '#02042c',
                        '&:hover': { borderColor: '#3333FF', bgcolor: 'rgba(74, 74, 255, 0.1)' }
                    }}
                    onClick={() => fileInputRef.current?.click()}
                    startIcon={<SearchOutlinedIcon />}
                >
                    Browse Files
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{
                        color: '#02042c',
                        borderColor: '#02042c',
                        '&:hover': { borderColor: '#3333FF', bgcolor: 'rgba(74, 74, 255, 0.1)' }
                    }}
                    onClick={downloadSampleFile}
                    startIcon={<FileDownloadOutlinedIcon />}
                >
                    Sample File
                </Button>
            </Box>
        </Box>
    );
};

export default FileUpload;