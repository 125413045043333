// FIFTH VERSION WITH UPLOAD LOGO OPTION IF THE CONTAINER IS EMPTY

import React, { useRef, useState, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Select, MenuItem, FormControl, Button } from '@mui/material';
import { toast } from 'sonner';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon
const UploadIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    style={{ width: "2rem", height: "2rem", color: "GrayText" }}
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </svg>
);

const InitialDetails = ({ assistantId }) => {
    const [token, setToken] = useState("");
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        getToken();
        loadSavedValues();
    }, []);

    const getToken = async () => {
        const tokenData = localStorage.getItem("token");
        if (tokenData) {
            const t = JSON.parse(tokenData).token;
            setToken(t);
        } else {
            console.error("Token not found in local storage");
        }
    };

    const loadSavedValues = () => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};
        setName(savedAssistant.Name || '');
        setCategory(savedAssistant.Category || '');
        setType(savedAssistant.Type || '');
        const savedLogo = localStorage.getItem('Logo');
        if (savedLogo) {
            const logoData = JSON.parse(savedLogo);
            fetch(logoData.content)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], logoData.name, { type: logoData.type });
                    setImage(file);
                });
        }
    };


    const updateAssistantInLocalStorage = (key, value) => {
        const savedAssistant = JSON.parse(localStorage.getItem("assistant")) || {};
        savedAssistant[key] = value;
        localStorage.setItem("assistant", JSON.stringify(savedAssistant));
    };

    const handleFile = (file) => {
        if (!file) return;

        const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml", "image/gif"];
        const maxSize = 3 * 1024 * 1024;

        if (!allowedTypes.includes(file.type)) {
            toast.error("Invalid file type. Only SVG, PNG, JPG, or GIF files are allowed.");
            return;
        }

        if (file.size > maxSize) {
            toast.error("File size should not exceed 3 MB.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const base64Image = reader.result;
            setImage(file);

            const logoData = {
                content: base64Image,
                name: file.name,
                type: file.type
            };
            localStorage.setItem('Logo', JSON.stringify(logoData));
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) handleFile(file);
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={12} lg={8}>
                <Box sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: ".5rem", 
                    bgcolor: "rgba(177, 177, 190, 0.11)", 
                    padding: "1.5rem", 
                    borderRadius: "0.5rem",
                    height: "100%"
                }}>
                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Name</Typography>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                updateAssistantInLocalStorage("Name", e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Category</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={category}
                                onChange={(e) => {
                                    setCategory(e.target.value);
                                    updateAssistantInLocalStorage("Category", e.target.value);
                                }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Customer Support">Customer Support</MenuItem>
                                <MenuItem value="IT Support">IT Support</MenuItem>
                                <MenuItem value="Human Resources">Human Resources</MenuItem>
                                <MenuItem value="Healthcare / Service Industry">Healthcare / Service Industry</MenuItem>
                                <MenuItem value="Banking Support">Banking Support</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 3, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Type</Typography>
                        <FormControl fullWidth size='small'>
                            <Select
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value);
                                    updateAssistantInLocalStorage("Type", e.target.value);
                                }}
                                sx={{ bgcolor: "white" }}
                            >
                                <MenuItem value="inbound">Inbound</MenuItem>
                                <MenuItem value="outbound">Outbound</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', gap: 1, flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 500 }}>Description</Typography>
                        <TextField
                            size='small'
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                updateAssistantInLocalStorage("Description", e.target.value);
                            }}
                            sx={{ bgcolor: "white" }}
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
    <Box sx={{ 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        height: "100%",
        minHeight: "400px"
    }}>
        <div 
            style={{
                width: "200px",
                height: "200px",
                position: "relative",
                cursor: "pointer",
                borderRadius: "50%",
                overflow: "hidden",
                backgroundColor: "rgba(177, 177, 190, 0.11)",
                border: isDragging ? "2px dashed #1d4ed8" : "2px dashed #D9D9D9",
            }}
            onDragEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(true);
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDragging(false);
            }}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
        >
            <input
                type="file"
                accept="image/*"
                id="upload-file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => handleFile(e.target.files?.[0])}
            />
            
            {!image ? (
                <div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "1rem"
                }}>
                    <UploadIcon />
                    <Typography 
                        variant="caption" 
                        sx={{ 
                            textAlign: "center", 
                            mt: 1, 
                            color: "GrayText",
                            fontSize: "0.7rem"
                        }}
                    >
                        SVG, PNG, JPG or GIF (max. 3 MB)
                    </Typography>
                </div>
            ) : (
                <img
                    src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                    alt="Preview"
                    style={{ 
                        width: "100%", 
                        height: "100%", 
                        objectFit: "cover"
                    }}
                />
            )}
        </div>
        
        <Button
            variant="contained"
            onClick={() => fileInputRef.current?.click()}
            sx={{ 
                textTransform: 'none',
                backgroundColor: '#02042D',
                color: 'white',
                padding: '6px 16px',
                borderRadius: '4px',
                '&:hover': {
                    backgroundColor: '#080A3C'
                }
            }}
        >
            {image ? 'Change Assistant Logo' : 'Upload Logo'}
        </Button>

        {image && (
            <Button
                variant="outlined"
                onClick={() => setImage(null)}
                sx={{ 
                    textTransform: 'none',
                    color: '#02042D',
                    borderColor: '#02042D',
                    padding: '6px 16px',
                    borderRadius: '4px',
                    '&:hover': {
                        borderColor: '#080A3C',
                        color: '#080A3C'
                    }
                }}
            >
                Remove Logo
            </Button>
        )}
    </Box>
</Grid>

        </Grid>
    );
};

export default InitialDetails;